export const alaska_mototurismo = '/magazines/alaska_mototurismo.epub'
export const argentina_mototurismo = '/magazines/argentina_mototurismo.epub'
export const australia_mototurismo = '/magazines/australia_mototurismo.epub'
export const bolivia_cile_dove = '/magazines/bolivia_cile_dove.epub'
export const canada_alaska = '/magazines/canada_alaska.epub'
export const canada_mototurismo = '/magazines/canada_mototurismo.epub.epub'
export const down_to_dakar_mototurismo = '/magazines/down_to_dakar_mototurismo.epub'
export const mediterraneo_mototurismo = '/magazines/mediterraneo_mototurismo.epub'
export const mongolia_mototurismo_ = '/magazines/mongolia_mototurismo_.epub'
export const mongolia_no_limits = '/magazines/mongolia_no_limits.epub'
export const over_andes_mototurismo = '/magazines/over_andes_mototurismo.epub'
export const per_mototurismo = '/magazines/per_mototurismo.epub'
export const senegal_mali_mototurismo = '/magazines/senegal_mali_mototurismo.epub'
export const sud_america_motociclismo = '/magazines/sud_america_motociclismo.epub'
export const transandina_onde = '/magazines/transandina_onde.epub'
export const transasia_mototurismo = '/magazines/transasia_mototurismo.epub'
export const tunisia_scooter = '/magazines/tunisia_scooter.epub'
export const tunisia_tell_sahel = '/magazines/tunisia_tell_sahel.epub'
export const turkestan = '/magazines/turkestan.epub'
export const vodoo_mototurismo = '/magazines/vodoo_mototurismo.epub'
export const west_africa_mototurismo = '/magazines/west_africa_mototurismo.epub'



import alaska_mototurismo_image from '../assets/magazines/alaska_mototurismo_1.jpg'
import argentina_mototurismo_image from '../assets/magazines/argentina_mototurismo_1.jpg'
import australia_mototurismo_image from '../assets/magazines/australia_mototurismo_1.jpg'
import bolivia_cile_dove_image from '../assets/magazines/bolivia_cile_dove_1.jpg'
import canada_alaska_image from '../assets/magazines/canada_alaska_1.jpg'
import canada_mototurismo_image from '../assets/magazines/canada_mototurismo_1.jpg'
import down_to_dakar_mototurismo_image from '../assets/magazines/down_to_dakar_mototurismo_1.jpg'
import mediterraneo_mototurismo_image from '../assets/magazines/mediterraneo_mototurismo_1.jpg'
import mongolia_mototurismo__image from '../assets/magazines/mongolia_mototurismo_1.jpg'
import mongolia_no_limits_image from '../assets/magazines/mongolia_no_limits_1.jpg'
import over_andes_mototurismo_image from '../assets/magazines/over_andes_mototurismo_1.jpg'
import per_mototurismo_image from '../assets/magazines/peru_mototurismo_1.jpg'
import senegal_mali_mototurismo_image from '../assets/magazines/senegal_mali_mototurismo_1.jpg'
import sud_america_motociclismo_image from '../assets/magazines/sud_america_motociclismo_1.jpg'
import transandina_onde_image from '../assets/magazines/transandina_onde_1.jpg'
import transasia_mototurismo_image from '../assets/magazines/transasia_mototurismo_1.jpg'
import tunisia_scooter_image from '../assets/magazines/tunisia_scooter_1.jpg'
import tunisia_tell_sahel_image from '../assets/magazines/tunisia_tell_sahel_1.jpg'
import turkestan_image from '../assets/magazines/turkestan_1.jpg'
import vodoo_mototurismo_image from '../assets/magazines/vodoo_mototurismo_1.jpg'
import west_africa_mototurismo_image from '../assets/magazines/west_africa_mototurismo_1.jpg'



const magazinesList = [
    {
        id: 1,
        image: alaska_mototurismo_image,
        url: alaska_mototurismo,
    },
    {
        id: 2,
        image: argentina_mototurismo_image,
        url: argentina_mototurismo,
    },
    {
        id: 3,
        image: australia_mototurismo_image,
        url: australia_mototurismo,
    },
    {
        id: 4,
        image: bolivia_cile_dove_image,
        url: bolivia_cile_dove,
    },
    {
        id: 5,
        image: canada_alaska_image,
        url: canada_alaska,
    },
    {
        id: 6,
        image: canada_mototurismo_image,
        url: canada_mototurismo,
    },
    {
        id: 7,
        image: down_to_dakar_mototurismo_image,
        url: down_to_dakar_mototurismo,
    },
    {
        id: 8,
        image: mediterraneo_mototurismo_image,
        url: mediterraneo_mototurismo,
    }, {
        id: 9,
        image: mongolia_mototurismo__image,
        url: mongolia_mototurismo_,
    }, {
        id: 10,
        image: mongolia_no_limits_image,
        url: mongolia_no_limits,
    }, {
        id: 11,
        image: over_andes_mototurismo_image,
        url: over_andes_mototurismo,
    }, {
        id: 12,
        image: per_mototurismo_image,
        url: per_mototurismo,
    }, {
        id: 13,
        image: senegal_mali_mototurismo_image,
        url: senegal_mali_mototurismo,
    }, {
        id: 14,
        image: sud_america_motociclismo_image,
        url: sud_america_motociclismo,
    }, {
        id: 15,
        image: transandina_onde_image,
        url: transandina_onde,
    }, {
        id: 16,
        image: transasia_mototurismo_image,
        url: transasia_mototurismo,
    }, {
        id: 17,
        image: tunisia_scooter_image,
        url: tunisia_scooter,
    },

    {
        id: 18,
        image: tunisia_tell_sahel_image,
        url: tunisia_tell_sahel,
    },
    {
        id: 19,
        image: turkestan_image,
        url: turkestan,
    },

    {
        id: 20,
        image: vodoo_mototurismo_image,
        url: vodoo_mototurismo,
    },
    {
        id: 21,
        image: west_africa_mototurismo_image,
        url: west_africa_mototurismo,
    }
]

export default magazinesList;