import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import icon from "../../assets/icons/about_us_icon.svg"
import "./about_us_header.css"
import abOne from "../../assets/about_us/ab_1.png"
import abTwo from "../../assets/about_us/ab_2.png"
import abThree from "../../assets/about_us/ab_3.png"


const AboutUsHeader = () => {
    return <Container fluid className="top">
        <Row className="padding-about-us">
            <Col>
               {/* Vedio  */}
                <iframe   width= "760"
                    height="427" src="https://www.youtube.com/embed/V7OdyQ2Bq8c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </Col>
            <Col className="title-header">
                <h1 className="text-teko">NWSE – In Motovunque Viaggiare</h1>
                <div className="sub-title-about-us">
                <img src={icon} alt='' />
                <h3 className="text-teko">Il Mondo è un libro e chi non viaggia ne legge solo una pagina</h3>
                </div>
                <p className="nanoSantaFont" style={{marginTop:40}} >Viaggiare in moto o con un 4x4, è sicuramente un modo per pensare, per rimettere a posto le idee, ed a volte per stare bene, per quanto sia possibile, anche con sé stessi. Viaggiare non è quindi solo preparare una borsa od un mezzo, viaggiare è cultura e conoscenza. La moto è un mezzo ideale in quanto ti fa assaporare tutti gli aspetti del viaggio, la stanchezza, il caldo, il sole, la pioggia e, come il 4x4, ti fa vivere un aspetto fondamentale del viaggio: il trasferimento. </p>
            </Col>
        </Row>
        <Row className="padding-about-us title-header">
            <p className="nanoSantaFont">
                Per poter proporre ed organizzare viaggi bisogna essere prima di tutto dei grandi appassionati, avendo quindi voglia di trasmettere e condividere le emozioni del viaggio con altre persone. Lo staff di NWSE si compone di persone che sono state prima di tutto dei viaggiatori. Con il 4x4 e soprattutto con la moto hanno percorso molti chilometri attraverso vari Paesi del Mondo, acquisendo esperienza da mettere a disposizione di chi ama viaggiare in estrema libertà. Proponiamo prevalentemente viaggi che danno la possibilità di far conoscere Paesi, realtà e costumi utilizzando i vostri mezzi di trasporto. Il catalogo propone itinerari in tutti i Continenti, con le caratteristiche di essere stati collaudati precedentemente, oltre con l'elemento distintivo di evidenziare l'essenza del viaggio e non del raid. NWSE garantisce il massimo impegno e grande professionalità per poter permettere a tutti di vivere avventure indimenticabili ... in Tutte le Direzioni. L'avventura è un avvenimento di solito unico, strano o singolare, non sempre è scalare l'Everest od attraversare un deserto in solitaria; per ognuno di noi l'avventura ha un significato personale, poter calcare il suolo africano con la propria moto e dormire in tenda tra le dune potrebbe essere un episodio bello per molti. Non occorre essere dei superman, basta riuscire a realizzare un evento che fino a poco prima era unico, strano o singolare. Intendiamo il viaggio come movimento, il trasferimento assume connotati e ragioni totalmente differenti, diventandone parte fondamentale; il viaggio è un'escursione unica, non si va solo per visitare me per approcciare, per conoscere e condividere. Certi aspetti prima di secondaria importanza o di intoppo diventano fondamentali, una semplice pausa pranzo può diventare l'aspetto peculiare del viaggio.
            </p>
        </Row>
        <Row className="padding-about-us">
            <Col>
                <img  alt='' src={abOne} className="images-chisiamo" />
            </Col>
            <Col>
                <img  alt='' src={abTwo} className="images-chisiamo" />
            </Col>
            <Col>
              <img  alt='' src={abThree} className="images-chisiamo" />
            </Col>
        </Row>
    </Container>
 }
export default AboutUsHeader;