import React from 'react';
import spanDiv from '../../assets/icons/span.svg';
import "./number.css";

const Number = ({ number, description, color }) => {
    return   <div className="text-teko">
                    <h1 className="numbers centerBlock">{number}</h1>
                    <img className="centerBlock" src={spanDiv}  alt='' />
                    <h5  className="centerBlock" style={{color:color,marginTop: '10px',marginBottom: '10px'}} >{description }</h5>
                </div>
}
export default Number; 