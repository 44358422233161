import React from 'react';

import { Container, Row, Col } from "react-bootstrap";
import './get-catalog-form-section.css'
import OurLastCatImag from '../../assets/our-last-catalog-img.png'
import InputElement from "../../elements/input-element/input-element";
import Button from "../../elements/button/button_element";
import { FaPaperPlane } from "react-icons/fa";
import emailjs from 'emailjs-com';
function refreshPage() {
    window.location.reload(false);
  }
  
const GetLastCatalogForm = () => {
    let alt = "Our Last Catalog Image"
    const sendEmail = async (e) => {
        e.preventDefault();   
        try {
          const result = await emailjs.sendForm('service_os5hmhl', 'template_q3rpttw', e.target, '5Q3U7mSRIR3GPQs5W');
          console.log('Email sent successfully:', result.text);
          refreshPage();
        } catch (error) {
          console.log('Error sending email:', error);
          if (error.status) {
            console.log(`Error status: ${error.status}`);
          }
          if (error.text) {
            console.log(`Error message: ${error.text}`);
          } else {
            console.log('Unknown error occurred while sending email.');
          }
        }
      };      
    return (
        <Container className="our-last-catalog-section">
            <Row>
                <Col md={6}>
                    <img src={OurLastCatImag} alt={alt} className="our-last-catalog-section-img" />
                </Col>
                <Col className="our-last-catalog-content" md={6}>
                    <Row className="form-header text-teko">
                        <h1>Il nostro ultimo catalogo </h1>
                        <h2>Scaricalo ora gratis!</h2>
                    </Row>
                    <Row>
                        <form className="our-last-catalog-form" id='myForm' onSubmit={sendEmail}>
                            <InputElement type="text" name="name" hintText="Inserisci nome" />
                            <InputElement type="email" name="from" hintText="Inserisci indirizzo email" />
                            <InputElement type="number" name="number" hintText="Inserisci numero di telefono" />
                            <Row>
                                <Button width=" 110px" height="43px" text="InVIA" icon={<FaPaperPlane className="icon-button" />} type="submit" />
                            </Row>
                        </form>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
export default GetLastCatalogForm;