import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/firestore";
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: "AIzaSyDog6SVcHCz0bAXeAM5F_sTDlAm1x64oRk",
  authDomain: "inmotoovunque.firebaseapp.com",
  projectId: "inmotoovunque",
  storageBucket: "inmotoovunque.appspot.com",
  messagingSenderId: "176971520051",
  appId: "1:176971520051:web:e6cd5dfbc1bdbdeb842b46",
};

firebase.initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
const storage = firebase.storage();
const fireStore = firebase.firestore();
const sendEmail = firebase.functions().httpsCallable("sendEmail");
const auth = getAuth(firebaseApp);
export { storage, sendEmail, fireStore, auth };
