import React from 'react';

import { Col, Container, Row } from "react-bootstrap";

import TeamCard from "../team-card/team-card";
import memberImage1 from "../../assets/team/team3.png"
import memberImage2 from "../../assets/team/team4.png"
import memberImage3 from "../../assets/team/team5.png"
import memberImage4 from "../../assets/team/team6.png"
import memberImage5 from "../../assets/team/team7.png"
import memberImage6 from "../../assets/team/team8.png"
import memberImage7 from "../../assets/team/team9.png"
import memberImage8 from "../../assets/team/team10.png"
import memberImage9 from "../../assets/team/team11.png"
import memberImage10 from "../../assets/team/team12.png"
import memberImage11 from "../../assets/team/team13.png"
import memberImage12 from "../../assets/team/team14.png"
import memberImage13 from "../../assets/team/team15.png"
import memberImage14 from "../../assets/team/team16.png"
import memberImage15 from "../../assets/team/team17.png"
import memberImage16 from "../../assets/team/team18.png"
import memberImage17 from "../../assets/team/team19.png"
import memberImage18 from "../../assets/team/team20.png"
import memberImage19 from "../../assets/team/team21.png"
import memberImage20 from "../../assets/team/team22.png"




import "./collaborators.css";

const Collaborators = () => {
        let collaborators = [
            {
            "memberName": "Habib",
            "city": "Tunisia",
            "image": memberImage1,
            "role":""
            },
             {
            "memberName": "Soliman",
            "city": "Algeria",
            "image": memberImage2,
            "role":""
            },
             {
            "memberName": "Rida",
            "city": "Libia",
            "image": memberImage3,
            "role":""
            },
            {
            "memberName": "Mohammed",
            "city": "Togo",
            "image": memberImage4,
            "role":""
            },
            {
            "memberName": "Sindoyo",
            "city": "Marrocco",
            "image": memberImage5,
            "role":""
            },
            {
            "memberName": "Daniel Owousu",
            "city": "Senegal",
            "image": memberImage6,
            "role":""
            },
            {
            "memberName": "Paco",
            "city": "Marrocco",
            "image": memberImage7,
            "role":""
            },
            {
            "memberName": "Sissoko",
            "city": "Tunisia",
            "image": memberImage8,
            "role":""
            },
            {
            "memberName": "Imed",
            "city": "Tunisia",
            "image": memberImage9,
            "role":""
            },
            {
            "memberName": "Dolo Lesserou",
            "city": "Dogon",
            "image": memberImage10,
            "role":""
            },
            {
            "memberName": "Idomohu",
            "city": "Burkinafaso",
            "image": memberImage11,
            "role":""
            },
               {
            "memberName": "N'disa",
            "city": "Benin",
            "image": memberImage12,
            "role":""
            },
            {
            "memberName": "Osman",
            "city": "Turchia",
            "image": memberImage13,
            "role":""
            },
            {
            "memberName": "Laureano",
            "city": "Argentina & Patagonia",
            "image": memberImage14,
            "role":""
            },
            {
            "memberName": "Andres",
            "city": "Argentina & Patagonia",
            "image": memberImage15,
            "role":""
            },
            {
            "memberName": "Beto",
            "city": "Peru",
            "image": memberImage16,
            "role":""
            },
            {
            "memberName": "Richard",
            "city": "Bolivia",
            "image": memberImage17,
            "role":""
            },
            {
            "memberName": "Dahli",
            "city": "Mauritania",
            "image": memberImage18,
            "role":""
            },
            {
            "memberName": "Enrique",
            "city": "Perù",
            "image": memberImage19,
            "role":""
            },
            {
            "memberName": "Grec",
            "city": "Alaska",
            "image": memberImage20,
            "role":""
            },
   
    ]
    
    return <Container fluid className="top ">
        <Row className="padding-about-us">
             <div className='title-section'>
                <h1 className=" text-teko" >I nostri</h1>
                <h1 className="title-tours text-teko" style={{ color: "#EB5223" }}>Collaboratori</h1>
            </div>
        </Row>
         
        <Row className="wrapper padding-about-us">
           {collaborators.map((collaborator, i) => <Col md={3} key={i} className="img-bottom" > <TeamCard image={collaborator.image} memberName={collaborator.memberName} role={collaborator.city} /></Col> )}
         </Row> 
    </Container>
}
export default Collaborators;