import React from 'react';
import "./tours-description-element.css"

const ToursDescriptionElement = ({ icon, description }) => { 
    let img = description; 
    return <div className="tours-element text-teko">
            <img className='tours-element-img' src={icon} alt={img} style={{
          width: 16,
          
        }} />
            <p className='tours-element-p'>{description}</p>
    </div>
}
export default ToursDescriptionElement;