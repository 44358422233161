import React, { useState, useEffect } from "react";
import "./App.css";
import Home from "./pages/home/home";
import { Route, Routes } from "react-router-dom";
import ChiSiamo from "./pages/about-us/about-us";
import Tours from "./pages/tours/tours";
import Footer from "./components/footer/footer";
import Media from "./pages/media/media-books";
import TravelingByMotorbike from "./pages/utilita/traveling_by_motorbike";
import Blog from "./pages/blog/blog";
import Contact from "./pages/contact/contact";
import BookDetails from "./components/book-details/book-details";
import GalleryPage from "./pages/media/gallery";
import Photos from "./pages/media/photos";
import ToursDetails from "./pages/tours/tours-details";
import swipeup from "./assets/whatsapp-logo.svg";
import AddTours from "./pages/admin/pages/add-tour";
import AddTestimonials from "./pages/admin/pages/add-testimonials";
import AddBook from "./pages/admin/pages/add-book";
import Suggestions from "./pages/utilita/suggestions";
import Desert from "./pages/utilita/desert";
import ShipmentMoto from "./pages/utilita/shipment_motorcycle";
import Health from "./pages/utilita/health";
import Login from "./pages/admin/login";

import { auth } from "../src/firebase_setup/firebase";
import Dashboard from "./pages/admin/dashboard";
import Magazines from "./pages/media/magazines";
import MagazineDetails from "./pages/media/magazine-details";
import AdminGallery from "./pages/admin/pages/gallery";
import AdminAlbum from "./pages/admin/pages/albums";
import TermsAndPolicies from "./pages/utilita/terms_and_policies";
import Assicurazioni from "./pages/utilita/assicurazioni";
function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  useEffect(() => {
    // Set up the listener for changes in authentication state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Function to check if the user is authenticated
  const isAuthenticated = () => {
    return !!user; // Convert the user object to a boolean
  };
  if (loading) {
    //add loading screen
    return;
  }
  const renderProtectedRoutes = () => {
    if (isAuthenticated()) {
      return (
        <>
          <Route path="/admin/add-tour" element={<AddTours />} />
          <Route path="/admin/add-testimonials" element={<AddTestimonials />} />
          <Route path="/admin/add-book" element={<AddBook />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/gallery" element={<AdminGallery />} />
          <Route path="/admin/gallery/:tour" element={<AdminAlbum />} />
        </>
      );
    }
  };
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about-us/" element={<ChiSiamo />} />
        <Route exact path="/tours" element={<Tours />} />
        <Route exact path="/books" element={<Media />} />
        <Route
          exact
          path="/traveling-by-motorbike"
          element={<TravelingByMotorbike />}
        />
        <Route exact path="/suggestions" element={<Suggestions />} />
        <Route exact path="/desert" element={<Desert />} />
        <Route exact path="/shipment" element={<ShipmentMoto />} />
        <Route exact path="/health" element={<Health />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/photos" element={<GalleryPage />} />
        <Route exact path="/magazines" element={<Magazines />} />
        <Route exact path="/magazines/:id" element={<MagazineDetails />} />
        <Route exact path="/termsAndPolicies" element={<TermsAndPolicies />} />
        <Route exact path="/assicurazioni" element={<Assicurazioni />} />


        <Route exact path="/super-admin" element={<Login />} />

        <Route path="/tours/:title" element={<ToursDetails />} />
        <Route path="/books/:id" element={<BookDetails />} />
        <Route path="/photos/:country" element={<Photos />} />
        <Route path="/photos/:country" element={<Photos />} />


        {renderProtectedRoutes()}
      </Routes>
      <Footer />

      <a
        href="https://wa.me/393426314585"
        target="_blank"
        rel="noopener noreferrer"
        id="back-to-top-btn"
        className="whatsappBtn"
      >
        <img src={swipeup} alt="GIF" />
      </a>
    </div>
  );
}

export default App;
