import React from 'react';
import { Row } from "react-bootstrap";
import './testimonial.css'
const testimonial = ({ from, message, title }) => {
    return <>
        <Row className='text-teko '>
            <Row className='testimonials-title'>{from}</Row>
            <Row className='testimonials-title'>{title}</Row>
            
            <Row className='message'><samp className='comma'>“</samp>{message}</Row></Row>
    </>
}
export default testimonial
