import React from 'react';

import { Card, Col, Row } from "react-bootstrap";
import hourIcon from "../../assets/icons/hour.svg"
import mapIcon from "../../assets/icons/map-icon.svg"
import roadIcon from "../../assets/icons/road.svg"
import DateElement from "../date-element/date-element.jsx";
import ToursDescriptionElement from "../tours-description/tours-description-element.jsx";
import './tour-card.css'




const ToursCard = ({ tour }) => {
    return <a href={`/tours/` + tour.id} className="tours-link">
        <Card className="tours-card">
            <Row>
                <div className="image-container">
                    <img alt='' src={tour.image} style={{ width: "100%", paddingRight: "10px" }} />
                    <div className="image-overlay"></div>
                </div>

            </Row>
            <div className="tours-card-content">
                <DateElement date={tour.startDate} />
                <Row style={{ marginTop: "35px" }}>
                    <h4 className="card-title text-teko">{tour.title} </h4>
                    <p className="nanoSantaFont card-details" >{tour.details}</p>
                </Row>
                <Row> <h3 className="text-teko">{tour.price} € </h3></Row>
                <Row>
                    <Col xs={12} sm={4} className="d-flex justify-content-center">
                        <ToursDescriptionElement icon={hourIcon} description={`${tour.days} giorni`} />
                    </Col>
                    <Col xs={12} sm={4} className="d-flex justify-content-center">
                        <ToursDescriptionElement icon={mapIcon} description={`${tour.km} Kms`} />
                    </Col>
                    <Col xs={12} sm={4} className="d-flex justify-content-center">
                        <ToursDescriptionElement icon={roadIcon} description={tour.tripType} />
                    </Col>
                </Row>
            </div>


        </Card>
    </a>



}
export default ToursCard; 