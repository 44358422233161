import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../elements/button/button_element';

import { Col, Container, Row } from "react-bootstrap";
import TitleLeft from "../../elements/section-title/title-left";
import "./gallery.css"
import tripOne from "../../assets/gallery/trip-1.png"
import tripTwo from "../../assets/gallery/trip-2.png"
import TripThree from "../../assets/gallery/trip-3.png"
import tripFour from "../../assets/gallery/trip-4.png"
import tripFive from "../../assets/gallery/trip-5.png"
import tripSix from "../../assets/gallery/trip-6.png"
import tripSeven from "../../assets/gallery/trip-7.png"
import { FaRegEye } from 'react-icons/fa';

const Gallery = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/photos/');
    };
    return <Container fluid className="gallery top-padding">
    <Row className="g-0">
        <Col xs={12} md={4} className="gallery-title-section">
            <TitleLeft title="le nostre foto" subtitle="la nostra immagine memorabile in tutto il mondo" className="title-gallery" />
            <Button type="button" height="240" width="518" text="Mostrami altro" icon={<FaRegEye className='icon-button' />} onClick={handleClick} />
            <div style={{marginBottom: 20}} ></div>
        </Col>

        <Col xs={12} md={8}>
            <Row>
                <Col xs={12} sm={6} className="d-flex">
                    <Row>
                        <Col xs={12} style={{marginBottom: 44}} className="d-flex">
                            <img alt='' src={tripOne} className='trip-gallery img-fluid flex-fill' />
                        </Col>
                        <Col xs={12} className="d-flex">
                            <img alt='' src={tripTwo} className='trip-gallery img-fluid flex-fill' />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={6} className="d-flex align-items-center justify-content-center">
                    <img alt='' src={TripThree} className="left-image-gallery img-fluid flex-fill" />
                </Col>
            </Row>
        </Col>
    </Row>
    <Row className="g-2">
        <Col xs={6} sm={3} className="d-flex">
            <img alt='' src={tripFour} className="trip-gallery img-fluid flex-fill" />
        </Col>
        <Col xs={6} sm={3} className="d-flex">
            <img alt='' src={tripFive} className="trip-gallery img-fluid flex-fill" />
        </Col>
        <Col xs={6} sm={3} className="d-flex">
            <img alt='' src={tripSix} className="trip-gallery img-fluid flex-fill" />
        </Col>
        <Col xs={6} sm={3} className="d-flex">
            <img alt='' src={tripSeven} className="trip-gallery img-fluid flex-fill" />
        </Col>
    </Row>
</Container>

}
export default Gallery;