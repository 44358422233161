import React  from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountdownTimer from "./countdown-timer.jsx";

import "./upcoming-events.css";
import { Link } from 'react-router-dom';

const UpcomingEvent = (props) => {
  let alText = 'image trip';
   

  return (
    <Container fluid>
    <Row className="p-0">
      <Col xs={12} md={6}>
        <Row className="upcoming-event">
          <Col xs={12} md={4} className="text-md-left">
            <p className="text-teko" style={{ color: "white", fontSize: "40px" }}>Prossimo Viaggio</p>
            <p className="event-name text-teko">{props.nextEvent.title}, {new Date(props.nextEvent.nextEventDate).toLocaleDateString('en-GB')}</p>
          </Col>
          <Col xs={12} md={8}>
            <CountdownTimer nextEventDate={props.nextEvent.nextEventDate} />
          </Col>
        </Row>
      </Col>
      <Col xs={12} md={3} className="upcoming-event-img text-center text-md-left">
        <img src={props.nextEvent.image} alt={alText} className="img-fluid" />
      </Col>
      <Col xs={12} md={3} className='join-us text-center text-md-left'>
        <p className="desc-title text-teko">Unisciti a noi in un viaggio memorabile</p>
        <Link to={`/tours/${props.nextEvent.id}`} className="see-more text-teko">Di più su questo viaggio</Link>
      </Col>
    </Row>
  </Container>
  
    
    

  )

};
export default UpcomingEvent;
