import { Col, Container, Row } from "react-bootstrap";
import "./tours-timeline-section.css"
import React from 'react';
import { Link } from 'react-scroll';


const ToursTimelineSection = ({ data }) => {


    return <Container className=' elementor-widget-container'>
 
        <div className  ="sidebar">
                <Row className="scroll-timeline-nav" >
                <nav>
                     <ul>
                        {data.map((tour, i) => {
                                                const formattedDate = new Date(tour.tripDate).toLocaleDateString('en-GB');

                         return       <li key={i}>
                             <Link to={`#section${formattedDate}`} > <span>{formattedDate}</span> </Link>
                       </li> 
                     }
                  
                         )}
                 </ul>
                </nav>
                </Row>
        </div>
        <div className="scrollable-container">
  <div className="scrollable-content">
                {data.map((tour, i) =>{
                    const formattedDate = new Date(tour.tripDate).toLocaleDateString('en-GB');

              return (            <section id={`#section${formattedDate}`} key={i} style={{marginLeft:"50px"}}>
           <Col className="scroll-timeline-wrapper container    " >
                <Row className='tour-date'>
                    <h3 className="text-teko">{formattedDate}</h3>
                </Row>
                <Row >
                    <h3 className="text-teko">{tour.dayName}</h3>
                </Row>
                <Row className='tour-sub-title'>
                <h5 className="text-teko">{tour.subtitleOfDay }</h5>
                </Row>
                <Row>
                <p className='nanoSantaFont'> {tour.description }</p>
                </Row>
                      { ( tour.hotle && tour.hotle.image !== undefined)  ?       
                            <Row className="hotel-section">
                                <Col>
                                    <img src={tour.hotle.image} alt='hotel' className='hotel-image'/>
                                </Col>
                              
                                <Col>
                                <Row>
                                    <h3 className="text-teko">{tour.hotle.name}</h3></Row>
                                    <Row> <p className='nanoSantaFont'>{tour.hotle.details}</p></Row>
                                </Col>
                            </Row>:<></>
                            }
          
        </Col>
            </section>)
          }

 )}
  </div>
</div>
  
        </Container>
 }
export default ToursTimelineSection;