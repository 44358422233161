import React from 'react';
import { Row, Col } from "react-bootstrap";
import backBtn from '../assets/icons/backBtn.png'
import nextBtn from '../assets/icons/nextBtn.png'

export default function ArrowBtn({handleSwitchText,goBackToPreviousText}) {
    return <Row  >
        <Col>
        <img src={backBtn} alt="" style={{ height: '60px' }} onClick={goBackToPreviousText}/> 
        <img src={nextBtn} alt="" style={{ height: '60px' }} onClick={handleSwitchText}/>
        </Col>
    </Row>
}