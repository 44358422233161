import React from 'react';
import HeaderPage from "../../components/secondary-hero-image/secondary_header_page";
import BooksList from '../../components/books-list/books-list.jsx';
import bgImage from '../../assets/book-cover.png'
import NavBar from "../../components/navbar/nav-bar";
function Media() {
    return <>
        <HeaderPage image={bgImage} title="Libri" path="HOME &gt; Libri" />
        <NavBar withBg={true} />
        <BooksList />
    </>
}
export default Media;