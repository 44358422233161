import React from 'react';
import { Container, Row } from 'react-bootstrap';
import VideoHeroImage from "../../components/video_hero_image/video_hero_image";

import './style.css'
import NavBar from '../../components/navbar/nav-bar';
function TermsAndPolicies() {
    return <>
        <Container className='alltext'>
            <NavBar withBg={false} />
            <div style={{ marginBottom: '150px' }}></div>
            <Row className='takoFont sub-title'>Terms and policies</Row>
            <Row>
            Gentile Signore/a, Desideriamo informarLa che il D.Lgs. n. 196 del 30 giugno 2003 (“Codice in materia di protezione dei dati personali”) prevede la tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali. Secondo la normativa indicata, tale trattamento sarà improntato ai principi di correttezza, liceità e trasparenza e di tutela della Sua riservatezza e dei Suoi diritti. Ai sensi dell’articolo 13 del D.Lgs. n. 196/2003, pertanto, Le forniamo le seguenti informazioni:
            </Row>
            <Row>
                1- I dati da Lei forniti verranno trattati per le seguenti fnalità: contatto informativo con InMotOvunque
            </Row>
            <Row>
                2-Il trattamento sarà effettuato con le seguenti modalità: informatizzato
            </Row>
            <Row>
                3- Il conferimento dei dati è facoltativo e l’eventuale rifuto di fornire tali dati non ha alcuna conseguenza se non la mancata prosecuzione del rapporto
            </Row>
            <Row>
                4- I dati non saranno comunicati ad altri soggetti, né saranno oggetto di diffusione.
            </Row>
            <Row>
                5- TIl titolare del trattamento è: inmotovunque.com
            </Row>       
            <Row>
                6- Il responsabile del trattamento è: Massimo Cavallo - Rue Bruxelles, 32 - 2450 - Raoued Plage - Tunisia - ciocio@nwse.eu
            </Row>          
        </Container>
        <div style={{ marginBottom: '50px' }}></div>
        <VideoHeroImage />
    </>
}
export default TermsAndPolicies;
