import React from 'react';
import AboutUsHeader from "../../components/about-us-header/about_us_header";
import HeaderPage from "../../components/secondary-hero-image/secondary_header_page";
import Collaborators from "../../components/collaborators/collaborators";
import chisiamo from '../../assets/chisiamo.png';
import Numbers from "../../components/numbers/numbers";
import StuffSection from '../../components/stuff_section/stuff_section'
import NavBar from "../../components/navbar/nav-bar";
import VideoHeroImage from "../../components/video_hero_image/video_hero_image";

import "./about-us.css"

const ChiSiamo = () => {

    return (
        <>
            <HeaderPage image={chisiamo} title="Chi Siamo" path="HOME &gt; CHI SIAMO" />
            <NavBar withBg={true} />
            <AboutUsHeader />
            <StuffSection />
            <div className="about-us-nembers ">
                <Numbers color='white' fluid={false} className="statistic" />
            </div>
            <Collaborators />
            <VideoHeroImage />

        </>
    )


}
export default ChiSiamo;