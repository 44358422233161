import React from 'react';
import locationIcon from '../../assets/locationIcon.svg'
import './section-title.css' ;
const SectionTitle = ({text}) => {

    return (
        <p className='subTitle'>
            <img  alt='' src={locationIcon} />
            {text}
            <img  alt='' src={locationIcon} />
        </p>

    )
}
export default SectionTitle; 