import React from 'react';
import './team-card.css';

const TeamCard = ({ memberName, role, image }) => {
    return (
        <div className="container-our-team">
            <div className="image-our-team selector" style={{ backgroundImage: `url(${(image)})`}}></div>
            <div className=' div-our-team text-teko'>
                <h2 className="our-team-name ">{memberName}</h2>
                <p>{role}</p>
                {/* <div className='social-media-team '>
                    <hr />
                    <img   alt='' src={instagramIcon} className="icon" />
                    <img   alt='' src={youtubeIcon} className="icon" />
                    <img   alt='' src={facebookIcon} className="icon" />
                </div> */}
            </div>
        </div>)
}
export default TeamCard;