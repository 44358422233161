import React from 'react';
import './footer.css';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import emailIcon from '../../assets/icons/emailIcon.svg'
import whatsappIcon from '../../assets/icons/whatsappIcon.svg'
import locationIcon from '../../assets/locationIcon.svg'
import facebookIcon from '../../assets/icons/facebookIcon.svg'
import instagramIcon from '../../assets/icons/instagramIcon.svg'
import youtubeIcon from '../../assets/icons/youtubeIcon.svg'
import messangerIcon from '../../assets/icons/messangerIcon.svg';
// import InputElement from "../../elements/input-element/input-element";

export default function Footer() {
    return (
        <MDBFooter className='text-center text-lg-start text-muted'>
            <section className='footer'>
                <MDBContainer className='text-center text-md-start mt-5'>
                    <MDBRow className='mt-3'>
                        <MDBCol md='3' lg='4' xl='4' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4 footerTitle'>
                            Vuoi più informazioni?<span className='orange'> Contattami!</span>
                            </h6>

                            <MDBRow>
                                <MDBCol> <img alt='' src={emailIcon} className="icon" />
                                info@inmotovunque.com </MDBCol>
                                <MDBCol> <img alt='' src={whatsappIcon} className="icon" />
                                    +39 3426314585</MDBCol>
                            </MDBRow>
                        </MDBCol>

                        <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4 takoFont'>LINK VELOCI</h6>
                            <p>
                                <a href='/tours' className='text-reset nanoSantaFont'>
                                    Tours
                                </a>
                            </p>
                            {/* <p>
                                <a href='/about-us' className='text-reset nanoSantaFont'>
                                    Video
                                </a>
                            </p> */}
                            <p>
                                <a alt='' href='/photos' className='text-reset nanoSantaFont'>
                                Gallery
                                </a>
                            </p>
                            <p>
                                <a href='/books' className='text-reset nanoSantaFont'>
                                    Libri
                                </a>
                            </p>
                            <p>
                                <a href='/magazines' alt='' className='text-reset nanoSantaFont'>
                                    Magazines
                                </a>
                            </p>
                        </MDBCol>

                        <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4 takoFont'>INFORMAZIONE</h6>
                            <p>
                                <a href='/about-us' className='text-reset  nanoSantaFont'>
                                    <img alt='' src={locationIcon} />  Chi siamo
                                </a>
                            </p>
                            <p>
                                <a href='/contact' className='text-reset  nanoSantaFont'>
                                    <img alt='' src={locationIcon} />  Contatto
                                </a>
                            </p>
                            <p>
                                <a href='/traveling-by-motorbike' className='text-reset  nanoSantaFont'>
                                    <img alt='' src={locationIcon} />   Termini & Condizioni
                                </a>
                            </p>
                            <p>
                                <a href='/contact' className='text-reset  nanoSantaFont'>
                                    <img alt='' src={locationIcon} />  Domande frequenti
                                </a>
                            </p>

                            <p>
                                <a href='/blog' className='text-reset  nanoSantaFont'>
                                    <img alt='' src={locationIcon} />   Blog
                                </a>
                            </p>
                        </MDBCol>

                        <MDBCol md='4' lg='3' xl='4' className='mx-auto mb-md-0 mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4 takoFont'>Seguici</h6>
                            <a href="https://www.instagram.com/inmotoovunque/" target="_blank" rel="noopener noreferrer">
                                <div className='border-box-icon'>
                                    <img alt='' src={instagramIcon} className="icon" />
                                </div>
                            </a>
                            <a href="https://youtube.com/@inmotovunque?feature=shared" target="_blank" rel="noopener noreferrer">
                                <div className='border-box-icon'>
                                    <img alt='' src={youtubeIcon} className="icon" />
                                </div>
                            </a>
                            <a href="https://m.me/inmotovunquenwse/" target="_blank" rel="noopener noreferrer">
                                <div className='border-box-icon'>
                                    <img alt='' src={messangerIcon} className="icon" />
                                </div>
                            </a>
                            <a href="https://www.facebook.com/inmotovunquenwse" target="_blank" rel="noopener noreferrer">
                                <div className='border-box-icon'>
                                    <img alt='' src={facebookIcon} className="icon" />
                                </div>
                            </a>
                            {/* <p className='takoFont'>
                                ISCRIVITI ALLA NOSTRA NEWSLETTER ESCLUSIVA
                            </p>
                            <div className='newsletter-input'>
                                <InputElement type="text" hintText="your@email.com"  />
                            </div> */}
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section >

            <div className='text-center p-4 copyrightSection' >
                Copyright © 2024. All Rights Reserved.
            </div>
        </MDBFooter >
    );
}