import React from 'react';
import './book-card.css'
export default function BookCard({id, title, image, by}) {
    return (
        <a className='book-link' href={`/books/`+id}>
         <div className="card" style={{border:'none', padding:'0px',position: 'relative', overflow: 'hidden', height: '100%' }}>
            <img  alt='' className="card-img-top" src={image} style={{ 
                      width: '100%', 
                      height: 'auto', 
                      maxHeight: '300px', 
                      objectFit: 'contain' 
                    }}  />
            <div className="card-body">
                <h5 className="card-title takoFont">{title}</h5>
                <p className="card-text takoFont"> <span style={{color:'#8E8E8E'}}>by </span>{by}</p>
            </div>
        </div>
        </a>

    );
}