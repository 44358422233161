import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import Number from "../../elements/number/number";
import "./numbers.css"
const Numbers = ({color , fluid})  => {
    return (<Container fluid={fluid} style={{marginTop:'20px'}}>
            <Row >
                <Col md={4} >
                    <Number  number="550,000" description="Km totali" color={color} />
                </Col>
                <Col md={4} >
                    <Number  number="98" description="Stati visitati" color={color} />
                </Col>
                <Col md={4} >
                    <Number  number="850.000" description="Km percorsi"  color={color}/>
            </Col>
  
            </Row>
    </Container>)
 }
export default Numbers; 