import React from 'react';
import locationIcon from '../../assets/locationIcon.svg'
import './title-left.css'

const TitleLeft = ({title, subtitle}) => { 
    return  <div className='title-section'>
    <p className='subTitle-tours-section text-teko'>
                   <img   alt='' src={locationIcon} />
                    {title} 
                </p>
                <h1 className="text-justify text-wrap text-teko" >{subtitle}</h1>
    </div>
               
}
export default TitleLeft;