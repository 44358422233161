import React from 'react';
import { ReactReader } from 'react-reader';
import magazines from '../../utils/magazines-data';
import { useParams } from "react-router-dom";
import HeaderPage from "../../components/secondary-hero-image/secondary_header_page";
import bgImage from '../../assets/gallery-background-image.png'
import NavBar from "../../components/navbar/nav-bar";
import { Container } from 'react-bootstrap';

function MagazineDetails() {
    const { id } = useParams();
    return (<>
        <HeaderPage image={bgImage} title="Magazines" path="HOME &gt; MEDIA &gt; Magazines" />
        <NavBar withBg={true} />
        <Container style={{ marginTop: '40px', marginBottom: '40px' }} >
            <div style={{ height: '100vh' }}>
                <ReactReader
                    url={magazines[id].url}
                />
            </div>
        </Container>
    </>
    );
}

export default MagazineDetails;
