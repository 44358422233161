import React from "react";
import { Container, Row } from 'react-bootstrap';
import VideoHeroImage from "../../components/video_hero_image/video_hero_image";
import './style.css'
import NavBar from '../../components/navbar/nav-bar';

const Assicurazioni =()=>{
    return <>
        <Container className='alltext'>
            <NavBar withBg={false} />
            <div style={{ marginBottom: '150px' }}></div>
            <Row className='takoFont sub-title'>ASSICURAZIONI</Row>
            <Row>
            Inmotovunque consiglia a tutti i partecipanti di stipulare una polizza medico/bagaglio, e, per chi vuole avere la tranquillità di non perdere l’importo del viaggio in caso di mancata partenza o rinuncia, l’organizzatore suggerisce in maniera totalmente FACOLTATIVA una polizza Annullamento viaggio. Queste polizze oramai possono essere stipulate o con il proprio assicuratore di fiducia, oppure sul web, in modo tale da redigere la polizza in base ai massimali e il dettaglio preferito.
            </Row>
            <Row>
            Di solito, la polizza annullamento copre: malattia, infortunio, decesso (dell'assicurato o del compagno di viaggio, del coniuge/convivente, di figli, genitori, fratelli, suoceri, generi, nuore, del Socio/Contitolare della Ditta dell'Assicurato); patologie della gravidanza; danni materiali a seguito di incendio o calamità naturali che colpiscano i beni dell'Assicurato ed impongano la sua presenza in loco; impossibilità a raggiungere, a seguito di calamità naturali, il luogo di partenza del viaggio organizzato; citazione in tribunale quale testimone o convocazione del Giudice Popolare pervenute successivamente alla data di decorrenza della garanzia.
            </Row>
            <Row>
            La polizza a copertura delle spese mediche, è particolarmente utile per i viaggi all'estero. In alcuni Stati, infatti, l'assistenza medica è particolarmente cara e a volte viene richiesto il possesso di una carta di credito senza limiti di plafond a garanzia del pagamento. Normalmente, la copertura assicurativa riguarda i costi sostenuti durante il viaggio, quali: parcelle mediche, ricoveri ospedalieri, interventi chirurgici, medicinali (se prescritti da un medico), cure dentali a seguito di infortuni. Inoltre, alcune assicurazioni prevedono la prestazione di assistenza attraverso: consulenza medica telefonica, invio di un medico in casi di urgenza, segnalazioni di specialisti all'estero, trasporto sanitario, rientro dei familiari o del compagno di viaggio, rientro del convalescente, anticipo spese di prima necessità, invio medicinali, etc. L'assicurazione bagagli copre in genere i danni materiali e diretti derivanti da furto e scippo, rapina, incendio e mancata riconsegna da parte del vettore aereo. In alcuni casi sono assicurate anche le spese documentate che sia stato necessario sostenere per gli acquisti di prima necessità in conseguenza di un ritardo nella riconsegna del bagaglio per oltre 12 ore da parte del vettore aereo. Occorre comunque sempre leggere attentamente le condizioni della polizza che viene proposta.
            </Row>        
        </Container>
        <div style={{ marginBottom: '50px' }}></div>
        <VideoHeroImage />
    </>
}
export default Assicurazioni;