import React from 'react';
import HeaderPage from "../../components/secondary-hero-image/secondary_header_page";
import bgImage from '../../assets/gallery-background-image.png'
import Grid from "../../components/grid/grid";
import Australia from "../../assets/gallery/cover-australia.png";
import Tunisia from "../../assets/gallery/cover-tunisia.png";
import Turkey from "../../assets/gallery/cover-turchia.png";
import Dakar from "../../assets/gallery/cover-dakar.png";
import Alaska from "../../assets/gallery/cover-canada.png";
import Oman from "../../assets/gallery/cover-oman.png";
import Marroco from "../../assets/gallery/cover-marroco.png";
import Patagonia from "../../assets/gallery/cover-patagonia.png";
import Cile from "../../assets/gallery/cover-cile.png";

import NavBar from "../../components/navbar/nav-bar";
function GalleryPage() {
    let galleryImage = [
        {
            image: Tunisia,
            title: "Tunisia",
            subtitle: "56 Foto",
        },
        {
            image: Marroco,
            title: "Marroco",
            subtitle: "21 Foto",
        },
        {
            image: Turkey,
            title: "Turchia",
            subtitle: "20 Foto",
        },
        {
            image: Patagonia,
            title: "Patagonia",
            subtitle: "33 Foto",
        },
        {
            image: Cile,
            title: "Over andes peru bolivia",
            subtitle: "56 Foto",
        },
        {
            image: Oman,
            title: "Oman",
            subtitle: "52 Foto",
        },
        {
            image: Dakar,
            title: "Dakar",
            subtitle: "50 Foto",
        },
        {
            image: Australia,
            title: "Australia",
            subtitle: "12 Foto",
        },
        {
            image: Alaska,
            title: "Canada Alaska",
            subtitle: "56 Foto",
        }
    ]
    return <>
        <HeaderPage image={bgImage} title="Gallery" path="HOME &gt; MEDIA &gt; Gallery" />
        <NavBar withBg={true} />
        <Grid items={galleryImage} />
    </>
}
export default GalleryPage;