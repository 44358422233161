import { Col, Row } from "react-bootstrap";
import "./secondary_header_page.css"
import timerIcon from '../../assets/icons/timer-icon.png'
import iterationIcon from '../../assets/icons/iteration-icon.png'
import gymIcon from '../../assets/icons/gym-icon.png'
import contactusIcon from '../../assets/icons/contactusIcon.svg'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContactModal from "../../elements/contact-modal/contact-modal";


const HeaderPage = ({ image, title, path, data }) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleClick = () => {
    navigate('/contact');
    };
    
    return <div className={(data === undefined)? "header-image-sm-bg": "header-image-bg"} style={{ backgroundImage: `url(${(image)})` }}>
        <h1 className='text-teko about-us-title'>{title}</h1>
        <p className="nanoSantaFont links-about-us">{path} </p>
   {     
            (data === undefined) ? <></> : <>
             <p className="nanoSantaFont description-text">{data.subTitle}</p>
                <Row style={{ width: '30%', color: 'white' }}>
            <Col md={4}  className="text-teko">
                <img  alt='' src={timerIcon} className="icon" /> {data.days} giorni
            </Col>
            <Col md={4}  className="text-teko">
                <img alt=''  src={iterationIcon} className="icon" />{data.km} KMs
            </Col>
            <Col md={4}  className="text-teko"> <img alt='' src={gymIcon} className="icon " />{ data.tripType}</Col>
        </Row>
        <Row md={12}>
            <Col md={6}><button onClick={handleClick} className="text-teko hero-image-btn">Prenotazione&ensp;|&ensp;<img alt='' src={contactusIcon} className="icon" /></button></Col>
            <Col md={6}><button onClick={handleShow} className="text-teko hero-image-btn hero-image-outline-btn">Più dettagli&ensp;|&ensp;<img alt='' src={contactusIcon} className="icon" /></button></Col>
            <ContactModal showModal={showModal} handleClose={handleClose} />
        </Row>
            </>}
    </div>

}
export default HeaderPage;