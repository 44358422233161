import React from 'react';
import './video_hero_image.css'
import SectionTitle from '../../elements/section-title/section-title'
import { useNavigate } from 'react-router-dom';
import Button from '../../elements/button/button_element';
import { FaPaperPlane } from 'react-icons/fa';

export default function VideoHeroImage() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact');
  };

  return (
    <div className="hero-image">
      <div className="hero-text">
        <SectionTitle text='Parti con noi!' />
        <h1>LA STRADA CHIAMA</h1>
        <p>Il viaggio in moto è diverso da tutti gli altri, sicuramente è il più scomodo, ma è un avventura, sempre, piccola o grande, non fa nessuna differenza. Le cose che osservi dietro la visiera del casco, non sono in sé, ne belle nè brutte, è il tuo giudizio su di esse che conferisce loro un valore.</p>
       <div className="center-button-contact">
         <Button type="button" height="240" width="518" text="Conttataci" icon={<FaPaperPlane className="icon-button" />} onClick={handleClick} />
       </div>
      </div>
    </div>

  );
}