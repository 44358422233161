import team1 from '../assets/stuff/team1.png';
import team2 from '../assets/stuff/team2.png'
import team3 from '../assets/stuff/team3.png';
import team4 from '../assets/stuff/team4.png';
import team23 from '../assets/team/team23.jpeg';
import team24 from '../assets/team/team24.jpeg';
import team25 from '../assets/team/team25.png';


export const team = [
    {
        id: 1,
        image: team1,
        name: "Ciocio Cavallo",
        title: "founder & Tour Leader",
        description: `”CIOCIO” Cavallo, collaboratore di importanti riviste di settore come Moto On The Road e Mototurismo, autore di 4 libri, “Destinazione NWSE”, “Il mondo sotto due ruote”, “Long Way Up” e “La moto secondo me”, e una Guida sulla Tunisia in moto “La Tunisia in moto secondo me”. Grande motociclista, ha girato il mondo in sella alla sua due ruote compiendo viaggi e vivendo grandi emozioni che poi ha raccontato anche in video, nelle trasmissioni RAI “Alle Falde del Kilimangiaro” e su SKY “Moto TV”.

        Svolge l’attività di organizzatore di viaggi in moto dal 2001, è stato Direttore Tecnico di diverse Agenzie Italiane, da 8 anni vive in Tunisia dove svolge l’attività di tour operator dove approfondisce la conoscenza e i tours in questo Paese, scoprendo itinerari unici e poco battuti. Parla inglese, francese, spagnolo e inizia a masticare l’arabo.

        La sua esperienza è tale, da infondere quella sicurezza che permette a chiunque di affrontare la strada in completa libertà in qualsiasi parte del mondo. Ritiene che la bellezza dei paesaggi, la storia, la cultura, le usanze, non sono che la cornice di quello splendido quadro che è il viaggio in moto.

        Per Ciocio, la cosa più affascinante di un viaggio, rimane però l’immedesimarsi in una realtà completamente diversa di quella che si è abituati a vivere. Fermarsi ad ammirare, vivere la vita normale e lo scorrere del tempo in un paese diverso, è l’aspetto che più accresce il significato del viaggio secondo lui. Alla moto deve I momenti più suggestivi dei suoi viaggi, le soste benzina, le foto, le forature, le pause interminabili nelle dogane e ai confini di stato, gli hanno fatto incontrare e conoscere gente speciale; nonostante il curriculum, per Ciocio, la difficoltà nel fare un viaggio in moto sta nell'avere l'idea di realizzarlo, tutto il resto è facile. Ciocio, è uno delle persone privilegiate che è riuscita a trasformare il suo hobby in lavoro; da 20 anni, infatti, ha messo a disposizione la sua esperienza, e trasmette la sua passione a molti altri motociclisti.

        Ha ripercorso tanti dei suoi itinerari, organizzando e seguendo personalmente il viaggio, grazie al suo tour operator IN MOTOVUNQUE. Tutti suoi viaggi sono contraddistinti da un titolo:`
        ,travelDestinations: [
            "TransAsia, dove ha percorso via terra il tragitto dall’Italia, attraversando la Grecia, la Turchia, l’Iran, il Pakistan e l’India;",
            " Silk Road, dove una volta arrivato in Iran ha deviato per il Turkmenistan, l’Uzbekistan, il Tagikistan, il Kirghizistan e il Kazakistan;",
           "West Africa, dove dal Marocco, passando per la Mauritania, il Senegal, il Mali, il Burkina Faso, il Ghana ed il Togo è arrivato in Benin;",
           "Over Andes, attraversando il Sud America dall’Argentina all’Ecuador passando per il Cile, la Bolivia e il Perù;",
           "Mediterraneo a Sinistra, in collaborazione con BMW e Mototurismo, ha visto Ciocio costeggiare il Mare Mediterraneo per tutto il suo perimetro transitando per il Marocco, l’Algeria, la Tunisia, la Libia, l’Egitto, la Giordania, la Siria, il Libano, la Turchia, la Grecia e l’Albania;",
           "altri Paesi come l’Australia, il Sud Est Asiatico, l’Oman, l’Alaska, il Canada, gli USA, la Cina, la Mongolia ed il Pamir completano una parte dei suoi viaggi."

        ]
    },
    {
        id: 2,
        image: team2,
        name: "ASIA CAVALLO",
        title: "founder & Tour Leader",
        description: "Asia conosce la Tunisia molto bene, per aver partecipato a diversi tour, parla perfettamente ARABO, FRANCESE e ITALIANO. Nonostante l'età, è dotata di ottima conoscenza del paese, disponibile e vivace ( troppo ), fondamentale come traduttrice."
    },
    {
        id: 3,
        image:team3,
        name: "Elena Lavora",
        title: "tour helper",
        description: "Elena lavora nell ambito turistico ormai dal 2010, e da questo anno, è parte dello staff di Inmotovunque. Cerca sempre la miglior soluzione, da dietro la scrivania, per i clienti. Ogni viaggio altrui e come se fosse anche un po’suo. Gestisce, prenota, modifica, calcola e cerca di risolvere i problemi in modo che i clienti trascorrano viaggi sereni. Lavora per i tour operator da anni e adora viaggiare, scoprire e tornare a casa con un pezzetto di mondo in più.Fa un lavoro che non si vede, ma si sente."
    },
    {
        id: 4,
        image: team4,
        name: "Gelindo e Antonella",
        title: "esperti conoscitori della Tunisia OFF ROAD",
        description: "Entrambe esperti conoscitori della Tunisia OFF ROAD in 4x4 e referenti per il Nord Est italiano di In moto Ovunque per viaggi off road in 4x4. Gelindo navigato meccanico e Anto dotata di resilienza e disponibilità. Titolari di Service Car, Santorso provincia di Vicenza, concessionaria multi-marca specializzata in preparazione di 4x4 per raid nel deserto."
    },
    {
        id: 5,
        image: team23,
        name: "ELISA MARLETTA",
        title: "Social Media Manager",
        description: "Responsabili della gestione del marketing e pubblicità dei social media di In Motovunque. Ci occupiamo principalmente della pubblicazione dei contenuti sul canale YouTube e piattaforme Instagram e Facebook."
    },
    {
        id: 6,
        image: team24,
        name: "ELISA VOLPE ",
        title: "Social Media Manager",
        description: "Responsabili della gestione del marketing e pubblicità dei social media di In Motovunque. Ci occupiamo principalmente della pubblicazione dei contenuti sul canale YouTube e piattaforme Instagram e Facebook."
    },
    {
        id: 7,
        image: team25,
        name: "Giorgio Rovero",
        title: "Social Media Manager",
        description: "Responsabili della gestione del marketing e pubblicità dei social media di In Motovunque. Ci occupiamo principalmente della pubblicazione dei contenuti sul canale YouTube e piattaforme Instagram e Facebook."
    }

]