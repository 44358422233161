import React,{ useState }from 'react';
import { Modal, Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles
import InputElement from "../../elements/input-element/input-element";
import contactusIcon from '../../assets/icons/contactusIcon.svg';
import emailjs from 'emailjs-com';

const ContactModal = ({ showModal, handleClose }) => {

  const [inputValue, setInputValue] = useState('');
  const minLength = 10;

  const sendEmail = async (e) => {
    e.preventDefault();
    emailjs.sendForm('service_os5hmhl', 'template_v4lxtae', e.target, '5Q3U7mSRIR3GPQs5W')
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        e.target.reset(); // Clear the form fields
        setInputValue("")
      })
      .catch((error) => {
        console.log('Error sending email:', error);
      });
  };
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className='text-teko'>Più dettagli</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="our-last-catalog-section text-teko">
          <Row>
            <Row className="form-header text-teko">
              <h1>NON ESITATE A CONTATTARCI</h1>
            </Row>
            <Row>
              <form className="our-last-catalog-form" id='myForm' onSubmit={sendEmail}>
                <InputElement type="text" name="name" hintText="Inserisci il tuo nome" />
                <InputElement type="email" name="from" hintText="Inserisci il tuo indirizzo email" />
                <InputElement type="number" name="number" hintText="Inserisci il tuo numero" />
                <textarea type="" placeholder="Inserisci il tuo messaggio" minLength={minLength} name="message"  value={inputValue}
                 onChange={handleChange} ></textarea>
                {inputValue.length < minLength && (
                  <p style={{ color: 'red' }}>Devi almeno digitare {minLength} caratteri.</p>
                )}
                <button className="text-teko hero-image-btn">InVIA&ensp;|&ensp;<img alt='' src={contactusIcon} className="icon" type="submit" /></button>
              </form>
            </Row>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ContactModal;
