import React from 'react';
import { fireStore, storage, firebaseConfig } from '../../../firebase_setup/firebase';
import firebase from 'firebase/compat/app';
import { Container } from 'react-bootstrap';
import './style.css'

class AddTours extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showHotelElements: !!props.hotelImage, 
      showFormIncluded: false,
      showFormNotIncluded: false,
      isEditMode: false,
      isEditTripMode: false,
      isEditTourMode: false,
      title: "",
      subTitle: "",
      startDate: "",
      price: 0,
      days: 0,
      km: 0,
      tripType: "",
      tours: [],
      includedItems: [],
      notIncludedItems: [],
      tripDetails: [],
      hotels: [],
      tripImages: [],
      tripImage: [],
      tripDate: "",
      dayName: "",
      subtitleOfDay: "",
      description: "",
      hotelName: '',
      hotelDetails: '',
      hotelImage: null,
      included: "",
      notIncluded: "",
      editedTour: null,
      editTour: null,
      disabled: false, 
    };
  }
  componentDidMount() {
    firebase.initializeApp(firebaseConfig);
    // Fetch all tours from Firestore and update the 'tours' state
    fireStore.collection('tours').onSnapshot(snapshot => {
      const tours = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      this.setState({ tours });
    });
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  options = [
    { value: '', text: '--Choose an option--' },
    { value: 'asfalto', text: 'Asfalto' },

  ];

  handleChange = e => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleCheckboxChange = (event) => {
    this.setState({
      showHotelElements: event.target.checked,
    });
  };

  handleAddIncluded = (e) => {
    e.preventDefault();
    const { included, includedItems } = this.state;
    if (included.trim() !== "") {
      this.setState({
        includedItems: [...includedItems, included],
        included: ""
      });
    }
  };

  handleAddNotIncluded = (e) => {
    e.preventDefault();
    const { notIncluded, notIncludedItems } = this.state;
    if (notIncluded.trim() !== "") {
      this.setState({
        notIncludedItems: [...notIncludedItems, notIncluded],
        notIncluded: ""
      });
    }
  };

  handleDeleteInclude = (index,e) => {
     e.preventDefault();
    console.log(index);
    this.setState(prevState => {
      const updatedItems = prevState.includedItems.filter((_, i) => i !== index);
      return { includedItems: updatedItems };
    });
  };

  handleDeleteNotInclude = (index,e) => {
     e.preventDefault();
    this.setState(prevState => {
      const updatedItems = prevState.notIncludedItems.filter((_, i) => i !== index);
      return { notIncludedItems: updatedItems };
    });
  };



  handleAddHotel = async (e) => {
    e.preventDefault();
    const { hotelName, hotelDetails, hotelImage, isEditMode } = this.state;

    if (!hotelName || !hotelDetails || !hotelImage) {
      alert('Please fill in all fields');
      return;
    }
    let hotelImageUrl ;
    if (hotelImage && typeof hotelImage !== 'string') {
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`hotel_image/${hotelImage.name}`);
      await imageRef.put(hotelImage).then(async () => {
        console.log("Image uploaded successfully!");
        hotelImageUrl = await imageRef.getDownloadURL();

      }).catch((error) => {
        console.error("Error uploading image: ", error);
      });
    } else if (hotelImage.startsWith("https://")) {
      hotelImageUrl = hotelImage; 
    }
    else {
      console.error("No image selected!");
    }
    const newHotel = {
      name: hotelName,
      details: hotelDetails,
      image: hotelImageUrl,
    };

    if (isEditMode) {
      // Edit mode: Update existing hotel
      this.setState({
        hotels: newHotel,
        hotelName: '',
        hotelDetails: '',
        hotelImage: null,
        isEditMode: false,
        editHotelIndex: null,
      });
    } else {
      // Add mode: Add new hotel
      this.setState(() => ({
        hotels: newHotel,
        hotelName: '',
        hotelDetails: '',
        hotelImage: null,
      }));
    }
    

  };

  handleEditHotel = (index, e) => {
    e.preventDefault();
    const { hotels } = this.state;
    const hotelToEdit = hotels;
console.log(hotelToEdit);
    this.setState({
      hotelName: hotelToEdit.name,
      hotelDetails: hotelToEdit.details,
      hotelImage: hotelToEdit.image,
      isEditMode: true,
      editHotelIndex: index,
    });
  };

  handleDeleteHotel = (index, e) => {
    e.preventDefault();
    const { hotels } = this.state;
    const hotelArray = Object.values(hotels);
  
    // Remove the hotel at the specified index
    hotelArray.splice(index, 1);
  
    // Convert the updated array back to an object
    const updatedHotels = hotelArray.reduce((obj, hotel, idx) => {
      obj[idx] = hotel;
      return obj;
    }, {});
  
    this.setState({
      hotels: updatedHotels,
    });
  };
  

  handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    console.log(event.target.name);
    this.setState({
      [event.target.name]: file
    });
  };

  handleFilesChange = (event) => {
   const files = event.target.files; // Get all the selected files
    const fileArray = Array.from(files); // Convert the FileList object to an array

    console.log(fileArray);
    console.log(event.target.name);

    // Update the state to store the array of files
    this.setState({
        [event.target.name]: fileArray
    });

  };

 handleAddTrip = (e) => {
  e.preventDefault();
  const { tripDate, dayName, subtitleOfDay, description, tripDetails, isEditTripMode, editTripIndex, hotels } = this.state;
  
  if (!tripDate || !dayName || !subtitleOfDay || !description) {
    alert('Please fill in all fields');
    return;
  }
console.log(hotels);
  const newTrip = {
    tripDate: tripDate,
    dayName: dayName,
    subtitleOfDay: subtitleOfDay,
    description: description,
    hotle: hotels  ? hotels : {},
  };

  if (isEditTripMode) {
    // Edit mode: Update existing Trip
    const updatedTripDetails = [...tripDetails];
    updatedTripDetails[editTripIndex] = newTrip;
console.log(newTrip);
    // Sort the tripDetails array based on tripDate after editing
    const sortedTripDetails = updatedTripDetails.sort((a, b) => new Date(a.tripDate) - new Date(b.tripDate));

    this.setState({
      tripDetails: sortedTripDetails,
      tripDate: '',
      dayName: '',
      subtitleOfDay: '',
      description: '',
      hotels: [],
      isEditTripMode: false,
      editTripIndex: null,
    });
  } else {
    // Add mode: Add new Trip
    const updatedTripDetails = [...tripDetails, newTrip];
    const sortedTripDetails = updatedTripDetails.sort((a, b) => new Date(a.tripDate) - new Date(b.tripDate));
    console.log(updatedTripDetails);
    this.setState({
      tripDetails: sortedTripDetails,
      tripDate: '',
      dayName: '',
      subtitleOfDay: '',
      description: '',
      hotels: [],
    });
  }
};



  handleEditTrip = (index, e) => {
    e.preventDefault();
    const { tripDetails } = this.state;
    const tripToEdit = tripDetails[index];
    this.setState({
      tripDate: tripToEdit.tripDate,
      dayName: tripToEdit.dayName,
      subtitleOfDay: tripToEdit.subtitleOfDay,
      description: tripToEdit.description,
      hotels:tripToEdit.hotle ? tripToEdit.hotle:{},
      showHotelElements: !!tripToEdit.hotle,
      isEditTripMode: true,
      editTripIndex: index,
    });
  };

  handleDeleteTrip = (e, index) => {
    e.preventDefault();
    this.setState(prevState => {
      const updatedTripDetails = [...prevState.tripDetails];
      updatedTripDetails.splice(index, 1);
      return { tripDetails: updatedTripDetails };
    });
  };
  handleAddImagesTrip = (e) => {
    e.preventDefault();
    const { tripImage, tripImages } = this.state;
    if (tripImage !== null) {
        this.setState({
            tripImages: [...tripImages, ...tripImage],
            tripImage: null
        });
    }
  };

  handleDeleteTripImages = (index, e) => {
    e.preventDefault();
    const { tripImages } = this.state;
    const updatedItems = tripImages.filter((_, i) => i !== index);
    this.setState({
      tripImages: updatedItems
    });
  };

  handleImageUpload = (image, name) => {
    let imageUrl = '';
    if (image) {
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`${name}/${image.name}`);
      imageRef.put(image).then(async () => {
        console.log("Image uploaded successfully!");
        imageUrl = await imageRef.getDownloadURL();
        return imageUrl;
      }).catch((error) => {
        console.error("Error uploading image: ", error);
      });
    } else {
      console.error("No image selected!");
    }

  };

  addTour = async e => {
    const {
      image,
      title,
      subTitle,
      startDate,
      price,
      days,
      km,
      details,
      tripType,
      tripImages,
      notIncludedItems,
      includedItems,
      tripDetails, editTour, disabled } = this.state;
    e.preventDefault();
        // Uploading the trip images to Firebase Storage
    let imageUrls = [];
    if (tripImages && Array.isArray(tripImages)) {
      for (let i = 0; i < tripImages.length; i++) {
        let imageTrip = tripImages[i];
        let imageUrlTrip;

        if (imageTrip && typeof imageTrip !== 'string') {
          console.log("Uploading image:", imageTrip.name);

          try {
            const storageRef = storage.ref();
            const imageRef = storageRef.child(`trip_images/${imageTrip.name}`);

            await imageRef.put(imageTrip);
            console.log("Image uploaded successfully!");
            imageUrlTrip = await imageRef.getDownloadURL();
          } catch (error) {
            console.error("Error uploading image: ", error);
            continue; // Skip this image and continue with the next one
          }
        } else if (typeof imageTrip === 'string' && image.startsWith("https://")) {
          imageUrlTrip = imageTrip;
        }

        if (imageUrlTrip) {
          imageUrls.push(imageUrlTrip);
        } else {
          console.error("Image URL is undefined for image:", imageTrip);
        }
      }
    } else {
      console.error("No images selected or tripImages is not an array!");
    }


    // Uploading the tour image (cover)
    let imageUrl;
    if (image && typeof image !== 'string') {
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`tour_image/${image.name}`);
      await imageRef.put(image).then(async () => {
        console.log("Image uploaded successfully!");
        imageUrl = await imageRef.getDownloadURL();

      }).catch((error) => {
        console.error("Error uploading image: ", error);
      });
    } else if (image.startsWith("https://")) {
      imageUrl = image; 
    }
    else {
      console.error("No image selected!");
    }

    if (editTour) {
      // Edit mode: Update existing tour
        await fireStore.collection('tours').doc(editTour.id).update({
          image: imageUrl,
          title,
          subTitle,
          startDate,
          price,
          days,
          km,
          details,
          tripType,
          tripImages:imageUrls,
          notIncludedItems: notIncludedItems,
          includedItems: includedItems,
          tripDetails: tripDetails,
          disabled
        });

        // Reset form fields and edit mode
        this.setState(() => ({
          showHotelElements: false,
          showFormIncluded: false,
          showFormNotIncluded: false,
          isEditMode: false,
          isEditTripMode: false,
          isEditTourMode: false,
          image: null,
          title: "",
          subTitle: "",
          startDate: "",
          details:"",
          price: 0,
          days: 0,
          km: 0,
          tripType: "",
          includedItems: [],
          notIncludedItems: [],
          tripDetails: [],
          hotels: [],
          tripImages: [],
          tripImage: [],
          tripDate: "",
          dayName: "",
          subtitleOfDay: "",
          description: "",
          hotelName: '',
          hotelDetails: '',
          hotelImage: null,
          included: "",
          notIncluded: "",
          editTour: null,
          editedTour: null,
          disabled:false
        }));

    } else {
      await fireStore.collection('tours').add({
        image: imageUrl,
        title,
        subTitle,
        startDate,
        price,
        days,
        km,
        details,
        tripType,
        tripImages:imageUrls,
        notIncludedItems: notIncludedItems,
        includedItems: includedItems,
        tripDetails: tripDetails,
        disabled,
      });
      this.setState(() => ({
        showHotelElements: false,
        showFormIncluded: false,
        showFormNotIncluded: false,
        isEditMode: false,
        isEditTripMode: false,
        isEditTourMode: false,
        image: null,
        title: "",
        subTitle: "",
        details:"",
        startDate: "",
        price: 0,
        days: 0,
        km: 0,
        tripType: "",
        includedItems: [],
        notIncludedItems: [],
        tripDetails: [],
        hotels: [],
        tripImages: [],
        tripImage: [],
        tripDate: "",
        dayName: "",
        subtitleOfDay: "",
        description: "",
        hotelName: '',
        hotelDetails: '',
        hotelImage: null,
        included: "",
        notIncluded: "",
        disabled: false
      }));


    }
  };

  handleEditTour = (tour, e) => {
    e.preventDefault();
    this.setState({
      image: tour.image,
      title: tour.title,
      subTitle: tour.subTitle,
      startDate: tour.startDate,
      price: tour.price,
      days: tour.days,
      km: tour.km,
      details: tour.details,
      tripType: tour.tripType,
      isEditTourMode: true,
      editTour: tour,
      includedItems: tour.includedItems,
      notIncludedItems: tour.notIncludedItems,
      tripDetails: tour.tripDetails,
      tripImages: tour.tripImages
    });
  };
  handleDeleteTour = async (tourId, e) => {
        e.preventDefault();
    try {
      await fireStore.collection('tours').doc(tourId).delete();
    } catch (error) {
      console.error("Error deleting book:", error);
    }
  };

  handleDisabledTour = async (tour, e) => {
    e.preventDefault();
    // Toggle the disabled status
    const updatedDisabled = !tour.disabled;

    // Update the tour in the state
    const updatedTours = [...this.state.tours];
    const index = updatedTours.findIndex((t) => t.id === tour.id);
    if (index !== -1) {
      updatedTours[index] = { ...tour, disabled: updatedDisabled };
      this.setState({ tours: updatedTours });
    }

    // Update the tour in Firebase
    await fireStore.collection('tours').doc(tour.id).update({ disabled: updatedDisabled });
  };

    // Add this method to sort tours based on the startDate
    sortToursByStartDate = () => {
      const sortedTours = [...this.state.tours].sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);
        return dateA - dateB;
      });

      this.setState({ tours: sortedTours });
    };

    // Call this method when you want to sort the tours, for example, in a button click event
  handleSortTours = (e) => {
    e.preventDefault();
      this.sortToursByStartDate();
    };
  
  render() {
    const {image, showHotelElements, showFormIncluded, showFormNotIncluded, includedItems, notIncludedItems, notIncluded, included, tripDate, tripDetails, dayName, subtitleOfDay, description, hotels, hotelName, hotelDetails,
      // hotelImage, tripImage,
      isEditMode, tripImages, isEditTripMode, tours, isEditTourMode,title,subTitle ,details,startDate,price,days,km, tripType} = this.state;
    
    return (
      <Container className='add-tour'>
        <form className='form-container' >
          <h1 className='text-teko'> Add tour </h1>
          {/* tour image */}
          <input type="file" name="image" onChange={this.handleFileChange} />
          {<img alt={image} src={image} width="25%" />}
          {/* Title */}
          <input type="text" name="title" placeholder="Title" onChange={this.updateInput} value={title}/>
          {/* subtitle */}
          <input type="text" name="subTitle" placeholder="Subtitle" onChange={this.updateInput} value={subTitle}/>
          {/* details  */}
          <textarea id="details" name="details" rows="4" cols="50" placeholder='Tour details' onChange={this.updateInput} value={details}></textarea>
          {/* date */}
          <input type="date" name="startDate" placeholder="Date" onChange={this.updateInput} value={startDate}/>
          {/* price */}
          <input type="number" name="price" placeholder="Price" onChange={this.updateInput} value={price}/>
          {/* days */}
          <input type="number" name="days" placeholder="Days" onChange={this.updateInput} value={days} />
          {/* Kms */}
          <input type="number" name="km" placeholder="km" onChange={this.updateInput} value={km}/>
          {/* type Asfalto or ... */}
          <select value={tripType} onChange={this.handleChange} name="tripType" id="type">
            {this.options.map(option => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>

          {/* included */}
          <div className='container'>
            {showFormIncluded ? (
              <div className="checkbox-container">
                <input type="text" name="included" placeholder="What is included" value={included} onChange={this.updateInput} />
                &nbsp;&nbsp;
                <button onClick={this.handleAddIncluded}>Add</button>
              </div>
            ) : (
              <button onClick={() => this.setState({ showFormIncluded: true })} className='add-button' >
                Add Included
              </button>
            )}

            <ul className="item-list">
              {includedItems.map((item, index) => (
                <li key={index}>
                  {item}  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                  <button className="delete-btn" onClick={(e) => this.handleDeleteInclude(index,e)}>Delete</button>
                </li>
              ))}
            </ul>
          </div>
          {/* not included */}
          <div className='container'>
            {showFormNotIncluded ? (
              <div className="checkbox-container">
                <input type="text" name="notIncluded" placeholder="What is not included" value={notIncluded} onChange={this.updateInput} />
                &nbsp;&nbsp;
                <button onClick={this.handleAddNotIncluded}>Add</button>
              </div>
            ) : (
              <button onClick={() => this.setState({ showFormNotIncluded: true })} className='add-button'>
                Add Not Include
              </button>
            )}

            <ul className="item-list">
              {notIncludedItems.map((item, index) => (
                <li key={index}>
                  {item}  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                  <button className="delete-btn" onClick={(e) => this.handleDeleteNotInclude(index,e)}>Delete</button>
                </li>
              ))}
            </ul>
          </div>
          {/* Trip Details */}
          <h4 className='text-teko'>Trip Details</h4>

          <input type="date" name="tripDate" placeholder="Date" onChange={this.updateInput} value={tripDate} />

          <input type="text" name="dayName" placeholder="Day Name" onChange={this.updateInput} value={dayName} />

          <input type="text" name="subtitleOfDay" placeholder="Subtitle" onChange={this.updateInput} value={subtitleOfDay} />

          <textarea id="description" name="description" rows="4" cols="50" placeholder='Description of the day' value={description} onChange={this.updateInput}></textarea>

          {/* hotel details */}

          <div className="checkbox-container">
            <input type="checkbox" id="tripDetails" name="tripDetails" value="Trip Details" onChange={this.handleCheckboxChange} />
            <label htmlFor="tripDetails" className='text-teko'>&nbsp;&nbsp;Is there a hotel?</label></div>
          {
            showHotelElements && (<Container fluid className='form-container'>

              <input type="text" name="hotelName" placeholder="Hotel Name" className="container" value={hotelName} onChange={this.updateInput} />
              <br></br>
              <textarea id="hotelDetails" name="hotelDetails" rows="4" cols="50" placeholder="Description of the hotel" className="container" value={hotelDetails} onChange={this.updateInput}></textarea>
              <br></br>
              <input type="file" name="hotelImage" placeholder="Tour image" className="container" onChange={this.handleFileChange} />
              <br />
              <button className='add-button' onClick={this.handleAddHotel}>{isEditMode ? 'Update Hotel' : 'Add Hotel'}</button>
              <br />
              {(hotels!=null)?
              <table>
                <thead>
                  <tr>
                    <th>Hotel Name</th>
                    <th>Details</th>
                    <th>Hotel Image</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {[hotels].map((hotel, index) => (
                    <tr key={index}>
                      <td>{hotel.name}</td>
                      <td> <p className='truncate-lines'>{hotel.details}</p> </td>
                      <td>{hotel.image && <img src={hotel.image} alt="Hotel" style={{ maxWidth: '100px' }} />}</td>
                      <td>
                        <button  style={{margin:"10px"}}  onClick={(event) => this.handleEditHotel(index, event)}>Edit</button>
                        <button className="delete-btn" onClick={(event) => this.handleDeleteHotel(index, event)}>Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>:<></>
}
            </Container>)
          }
          <Container >
            <br />
            <button className='add-button' onClick={this.handleAddTrip}>{isEditTripMode ? 'Update Trip' : 'Add Trip'}</button>
            <br />             <br />
            <table className='container' >
              <thead>
                <tr>
                  <th>Day of the trip</th>
                  <th>Day Name</th>
                  <th>Subtitle of the day </th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tripDetails.map((tripDetail, index) => (
                  <tr key={index}>
                    <td>{tripDetail.tripDate}</td>
                    <td>{tripDetail.dayName}</td>
                    <td>{tripDetail.subtitleOfDay}</td>
                    <td> <p className='truncate-lines'>{tripDetail.description}</p> </td>
                    <td>
                      <button  style={{margin:"10px"}}  onClick={(event) => this.handleEditTrip(index, event)}>Edit</button>
                      <button className="delete-btn" onClick={(e) => this.handleDeleteTrip(e,index)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Container>

          <h4 className='text-teko'>Add trip images</h4>
          <input
            type="file"
            name="tripImage"
            placeholder="Trip images"
            onChange={this.handleFilesChange}
            multiple
          />
          <button onClick={this.handleAddImagesTrip} className='add-button'>Add Images</button>

          <table className="item-table">
            <tbody>
              {tripImages.map((item, index) => (
                index % 3 === 0 ? (
                  <tr key={index}>
                    <td>
                      {item && <img alt={`tripImage-${index}`} src={item} width="100%" />}
                      {item && <button className="delete-btn" onClick={(e) => this.handleDeleteTripImages(index, e)}>Delete</button>}
                    </td>
                    {tripImages[index + 1] && (
                      <td>
                        {tripImages[index + 1] && <img alt={`tripImage-${index + 1}`} src={tripImages[index + 1]} width="100%" />}
                        {tripImages[index + 1] && <button className="delete-btn" onClick={(e) => this.handleDeleteTripImages(index + 1, e)}>Delete</button>}
                      </td>
                    )}
                    {tripImages[index + 2] && (
                      <td>
                        {tripImages[index + 2] && <img alt={`tripImage-${index + 2}`} src={tripImages[index + 2]} width="100%" />}
                        {tripImages[index + 2] && <button className="delete-btn" onClick={(e) => this.handleDeleteTripImages(index + 2, e)}>Delete</button>}
                      </td>
                    )}
                  </tr>
                ) : null
              ))}
            </tbody>
          </table>




          <button onClick={this.addTour}>{isEditTourMode ? 'Edit tour' : 'Submit'}</button>
          <br />
          <button style={{
          }} onClick={(e)=>this.handleSortTours(e)}>Sort Tours</button>
           <br />
          <table className='container' >
            <thead>
              <tr>
                <th>Image of the trip</th>
                <th>Title</th>
                <th>Subtitle </th>
                <th>Date of the trip</th>
                <th>Km</th>
                <th>Description</th>
                <th>Price</th>
                <th>Type of trip</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tours.map((tour, index) => (
                <tr key={index}>
                  <td><img src={tour.image} alt="Hotel" style={{ maxWidth: '100px' }} /></td>
                  <td>{tour.title}</td>
                  <td>{tour.subTitle}</td>
                  <td>{tour.days}</td>
                  <td>{tour.km}</td>
                  <td ><p className='truncate-lines'> {tour.details}</p></td>
                  <td>{tour.price}</td>
                  <td>{tour.tripType}</td>
                  <td>
                    <button style={{margin:"10px"}} onClick={(event) => this.handleEditTour(tour, event)}>Edit</button>
                    <br/>
                    <button className="delete-btn" style={{margin:"10px"}} onClick={(event) => this.handleDeleteTour(tour.id, event)}>Delete</button>
                     <br/>
                      <button
                      style={{ margin: '10px' }}
                      onClick={(e) => this.handleDisabledTour(tour,e)}
                    >
                      {tour.disabled ? 'Reactivate' : 'Disable'}
            </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </form>
      </Container>

    );
  }
}
export default AddTours;