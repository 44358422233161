import React from 'react';
import { Container, Row } from "react-bootstrap";
import './grid.css'

export default function Grid({ items }) {
    return (<>
        <Container style={{ marginTop: '40px', marginBottom: '40px' }} >
            <Row align="center">
                {items.map((item, i) =>
                    <Row className='gallery-image' style={{ backgroundImage: `url(${(item.image)})`, backgroundSize: '400px 300px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} key={i}>
                        <a href={"/photos/" + item.title} id="links" color='#FFFFFF'>
                            <Row className="text-teko content" style={{ height: '100px' }} align="center">
                                <div className="card-title-">{item.title} </div>
                                <br></br>
                                {/*<div className="subtitle">{item.subtitle}</div>*/}
                            </Row>
                        </a>
                    </Row>
                )}
            </Row>
        </Container>


    </>);
}