import React,{ useState, useEffect }  from 'react';
import {  Container , Row , Col} from "react-bootstrap";
import "./tours-list.css"
import ToursCard from "../../elements/tour-card/tour-card";
import { fireStore } from '../../firebase_setup/firebase';


const ToursList = () => {
      const [tours, setTours] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await fireStore.collection('tours').get();
        const toursData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Sort tours by start date from nearest to farther
        const currentDate = new Date();
        const sortedTours = toursData.sort((a, b) => {
          const dateA = new Date(a.startDate);
          const dateB = new Date(b.startDate);
          return Math.abs(currentDate - dateA) - Math.abs(currentDate - dateB);
        });

        // Update the tours state with the sorted tours
        setTours(sortedTours);
        const filteredTours = sortedTours.filter(tour => !tour.disabled);
         setTours(filteredTours);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the fetchData function

    // You can add cleanup code here if needed
    return () => {
      // Cleanup code here
    };
  }, []); // The empty dependency array ensures that this effect runs only once on mount
    return <Container fluid className="top-padding">
    <div className="tours-section-grid">
      {tours.length > 0 ? (
        <Row>
          {tours.filter((tour) => !tour.disabled).map((tour, i) => (
            <Col xs={12} sm={6} md={4} lg={3} key={i} className="mb-4">
              <ToursCard tour={tour} />
            </Col>
          ))}
        </Row>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  </Container>
}
export default ToursList;