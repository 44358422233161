import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row } from "react-bootstrap";
import { storage, firebaseConfig } from '../../firebase_setup/firebase';
import firebase from 'firebase/compat/app';
import NavBar from "../../components/navbar/nav-bar";
import ImageViewer from 'react-simple-image-viewer';
import { useLocation } from 'react-router-dom';

function Photos() {
  const [photoUrls, setPhotoUrls] = useState([]);
  const tour = useLocation().pathname.split('/')[2];
  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
    storage.ref()
      .child('/' + tour.toLocaleLowerCase())
      .listAll()
      .then((res) => {
        console.error(res);

        const promises = res.items.map((itemRef) =>
          itemRef.getDownloadURL()
        );
        Promise.all(promises)
          .then((urls) => {
            const formattedUrls = urls.map((url) => (url));
            setPhotoUrls(formattedUrls);
            console.error(formattedUrls);
          })
          .catch((error) => {
            console.error('Error retrieving photo URLs:', error);
          });
      })
      .catch((error) => {
        console.error('Error retrieving photos:', error);
      });
  }, []);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <>
      <NavBar />
      <Container fluid >
        <Row style={{ marginTop: '150px' , marginLeft:'60px'}}>
          {photoUrls.map((photo, i) =>
            <Row onClick={() => openImageViewer(i)} style={{ backgroundImage: `url(${(photo)})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '300px', width: '400px', margin: '5px', cursor: 'pointer' }} key={i}>
            </Row>
          )}
          {isViewerOpen && (<Container fluid >
            <ImageViewer
              src={photoUrls}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.6)"
              }}
            /></Container>)}
        </Row>
      </Container>
    </>
  );
};

export default Photos;


