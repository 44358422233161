import React,{ useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../../components/navbar/nav-bar"
import HeaderPage from "../../components/secondary-hero-image/secondary_header_page"
import bgImage from '../../assets/contact-page-cover.png'
import EmailIcon from '../../assets/icons/email.png'
import InfoIcon from '../../assets/icons/info-bulle.png'
import WebIcon from '../../assets/icons/earth.png'
import WhatsappIcon from '../../assets/icons/whatsapp.png'
import InputElement from "../../elements/input-element/input-element";
import Button from "../../elements/button/button_element";
import { FaPaperPlane } from "react-icons/fa";
import './contact.css';
import emailjs from 'emailjs-com';


function Contact() {
  const [inputValue, setInputValue] = useState('');
  const minLength = 10;
  const sendEmail = async (e) => {
    e.preventDefault();
    emailjs.sendForm('service_os5hmhl', 'template_v4lxtae', e.target, '5Q3U7mSRIR3GPQs5W')
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        e.target.reset(); // Clear the form fields
        setInputValue("")

      }, (error) => {
        console.log('Error sending email:', error.text);
      })
      .catch((error) => {
        console.log('Error sending email:', error);
      });
  };
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  return (
    <>
      <HeaderPage image={bgImage} title="contact" path="HOME &gt; contact" />
      <Container className="our-last-catalog-section text-teko">
        <Row>
          <Col md={6}>
            <h1>In moto ovunque</h1>
            <p className='nanoSantaFont'>é un organizzatore italiano operativo in TUNISIA dal 2015</p>
            <Row style={{ paddingTop: "16px" }}>
              <Col md={2}>
                <img src={WebIcon} alt='website' style={{ height: "20", width: '20' }} />
              </Col>
              <Col>
                <Row>  NWSE Consulting Suarl</Row>
                <Row className='nanoSantaFont'>Rue Bruxelle, 32 - 24566 - <br></br>
                  RAOUED PLAGE - TUNISIE</Row>
                <Row></Row>
              </Col>
            </Row>
            <Row style={{ paddingTop: "16px" }}>
              <Col md={2}>
                <img src={WhatsappIcon} alt='website' style={{ height: "20", width: '20' }} />
              </Col>
              <Col>
                <Row>Chimare l`assistenza</Row>
                <Row className='nanoSantaFont'>+216 95551332
                  +39 3426314585</Row>
                <Row></Row>
              </Col>
            </Row>
            <Row style={{ paddingTop: "16px" }}>
              <Col md={2}>
                <img src={EmailIcon} alt='website' style={{ height: "20", width: '20' }} />
              </Col>
              <Col>
                <Row>mandaci una email</Row>
                <Row className='nanoSantaFont'>ciocio@nwse.eu
                  Mail: info@inmotovunque.com</Row>
                <Row></Row>
              </Col>
            </Row>
            <Row style={{ paddingTop: "16px" }}>
              <Col md={2}>
                <img src={InfoIcon} alt='website' style={{ height: "20", width: '20' }} />
              </Col>
              <Col>
                <Row>ulteriori informazioni</Row>
                <Row className='nanoSantaFont'>Reg. Identification en douane: A614698
                  P.IVA: TN 1321586/H</Row>
                <Row></Row>
              </Col>
            </Row>
          </Col>
          <Col className="our-last-catalog-content" md={6}>
            <Row className="form-header text-teko">
              <h1>NON ESITATE A CONTATTARCI</h1>
            </Row>
            <Row >
              <form className="our-last-catalog-form" id='myForm' onSubmit={sendEmail}>
                <InputElement type="text" name="name" hintText="Inserisci nome" />
                <InputElement type="email" name="from" hintText="Inserisci indirizzo email" />
                <InputElement type="number" name="number" hintText="Inserisci numero di telefono" />
                <textarea type="" placeholder="Inserisci il tuo messaggio" minLength={minLength} name="message"  value={inputValue}
                 onChange={handleChange} ></textarea>
                {inputValue.length < minLength && (
                  <p style={{ color: 'red' }}>Devi almeno digitare {minLength} caratteri.</p>
                )}
                <Row>
                  <Button width="110px" height="43px" text="InVIA" icon={<FaPaperPlane className="icon-button" />} type="submit" />
                </Row>
              </form>
            </Row>
            
          </Col>
        </Row>
      </Container>

      <NavBar withBg={true} />
    </>
  );
}

export default Contact;
