import React , { useState, useCallback, useEffect }from 'react';
import NavBar from "../../components/navbar/nav-bar";
import HeaderPage from "../../components/secondary-hero-image/secondary_header_page";
import "./tours-details.css"
import ToursTimelineSection from '../../components/tours-timeline-section/tours-timeline-section';
import { Col, Container, Row } from 'react-bootstrap';
import check_circle from "../../assets/check_circle.png";
import check_notIncluded from "../../assets/icons-cros.png"
import { useLocation } from 'react-router-dom';
import { fireStore } from '../../firebase_setup/firebase';


function ToursDetails() {
  const location = useLocation();
  const tourId = location.pathname.split('/')[2];
  const [, setTours] = useState([]);
  const [selectedTour, setSelectedTour] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  
  const fetchData = useCallback(() => {
        fireStore.collection('tours').onSnapshot(snapshot => {
            const toursData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setTours(toursData);
            if (toursData.length > 0) {
                setSelectedTour(toursData.find((tour) => tour.id === tourId));
            }
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

  return <> {
      isLoading ? ( // Show loading state if data is still being fetched
                <div>Loading...</div>
    ) : <>
     <HeaderPage image={selectedTour.image} title={selectedTour.title} path="HOME &gt; Tours" data={selectedTour}  />
        <NavBar withBg={true} />
        <p className='nanoSantaFont'style={{margin:"50px"}}>{ selectedTour.details}</p>
      {
       <ToursTimelineSection data ={selectedTour.tripDetails} />
      }

      <Container  className='top'>
            <h3 className="text-teko">la logistica</h3>
            <Row className='logistica-section'>
            <Col className='pros'>
              {selectedTour.includedItems.map((inclu,i)=><div className="prps-row" key={i}>
                          <img alt='pros' src={check_circle} /> 
                <p className='nanoSantaFont' >{ inclu}</p>
                      </div>)}
            </Col>
            <Col className='pros'>
                {selectedTour.notIncludedItems.map((inclu,i)=><div className="prps-row" key={i}>
                          <img alt='pros' src={check_notIncluded} /> 
                <p className='nanoSantaFont' >{ inclu}</p>
                      </div>)}
              </Col>
            </Row>
            <br />
         <h3 className="text-teko">fotografie</h3>
         <Row className="wrapper-trip-images">
          <Row className="justify-content-center">
            {selectedTour.tripImages.map((image, i) => (
              <Col key={i} xs={6} sm={6} md={3} className="image-col-trip-images">
                <div className="image-container-trip-images">
                  <img src={image} alt='trip-images' className='trip-images-trip-images img-fluid' />
                </div>
              </Col>
            ))}
          </Row>
    </Row>
        </Container>
      </>
    }
      
       
    </>
 }
export default ToursDetails;