import React from 'react';
import { fireStore, storage } from '../../../firebase_setup/firebase';
class AddBook extends React.Component {
    constructor() {
        super();
        this.state = {
            images: [],
            title: '',
            by: '',
            link: '',
            description: '',
            books: [],
            isEditing: false,
            editedBook: null,
        };
    }

    componentDidMount() {
        // Fetch all books from Firestore and update the 'books' state
        fireStore.collection('books').onSnapshot(snapshot => {
            const books = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            this.setState({ books });
        });
    }
    addBook = async e => {
        e.preventDefault();
      
        const { images, title, by, link, description, editedBook } = this.state;
      
        const imageUrls = [];
      
        // Uploading multiple images to Firebase Storage
        for (let i = 0; i < images.length; i++) {
          const image = images[i];
          const imageRef = storage.ref(`book_images/${image.name}`);
          await imageRef.put(image);
          const imageUrl = await imageRef.getDownloadURL();
          imageUrls.push(imageUrl);
        }
      
        // If editedBook is not null, update the book
        if (editedBook) {
          try {
            await fireStore.collection('books').doc(editedBook.id).update({
              title,
              by,
              link,
              description,
              images: imageUrls, // Store the updated image URLs
            });
      
            // Reset form fields and edit mode
            this.setState({
              images: [],
              title: '',
              by: '',
              link: '',
              description: '',
              isEditing: false,
              editedBook: null,
            });
          } catch (error) {
            console.error("Error updating book:", error);
          }
        } else { // If editedBook is null, add a new book
          // Saving book data and image URLs to Firestore
          await fireStore.collection('books').add({
            title,
            by,
            link,
            description,
            images: imageUrls, // Store the image URLs in the 'images' field
          });
      
          this.setState({
            images: [],
            title: '',
            by: '',
            link: '',
            description: '',
          });
        }
      };

    // Function to toggle edit mode and set the book being edited
    toggleEditMode = (book) => {
        this.setState({
            isEditing: !this.state.isEditing,
            editedBook: book,
            images: book.images,
            title: book.title,
            by: book.by,
            link: book.link,
            description: book.description,
        });
    };

    deleteBook = async (bookId) => {
        try {
            await fireStore.collection('books').doc(bookId).delete();
        } catch (error) {
            console.error("Error deleting book:", error);
        }
    };

    updateInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    handleFileChange = e => {
        this.setState({
            images: e.target.files
        });
    }


    updateInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    // Function to handle book updates
    handleUpdate = async (e) => {
        e.preventDefault();

        const { images, title, by, link, description, editedBook } = this.state;

        const imageUrls = [];

        // Uploading multiple images to Firebase Storage
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            const imageRef = storage.ref(`book_images/${image.name}`);
            await imageRef.put(image);
            const imageUrl = await imageRef.getDownloadURL();
            imageUrls.push(imageUrl);
        }

        // Update book data and image URLs in Firestore
        try {
            await fireStore.collection('books').doc(editedBook.id).update({
                title,
                by,
                link,
                description,
                images: imageUrls, // Store the updated image URLs
            });

            // Reset form fields and edit mode
            this.setState({
                images: [],
                title: '',
                by: '',
                link: '',
                description: '',
                isEditing: false,
                editedBook: null,
            });
        } catch (error) {
            console.error("Error updating book:", error);
        }
    };




    render() {
        const { books } = this.state;
        return (
            <div style={{ padding: '24px' }}>
                <form onSubmit={this.addBook} style={{ width: '500px' }}>
                    <input
                        type="file"
                        name="images"
                        placeholder="book cover"
                        onChange={this.handleFileChange}
                        multiple // Allow multiple image selection
                    />
                    <input
                        type="text"
                        name="title"
                        placeholder="title"
                        onChange={this.updateInput}
                        value={this.state.title}
                    />
                    <input
                        type="text"
                        name="by"
                        placeholder="author"
                        onChange={this.updateInput}
                        value={this.state.by}
                    />
                    <input
                        type="url"
                        name="link"
                        placeholder="link"
                        onChange={this.updateInput}
                        value={this.state.link}
                    />
                    <input
                        type="textarea"
                        name="description"
                        placeholder="description"
                        onChange={this.updateInput}
                        value={this.state.description}
                    />
                    <button type="submit">Submit</button>
                </form>
                {books && books.length > 0 ? (
                    <table style={{ width: '100%', marginTop: '24px', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Author</th>
                                <th>Link</th>
                                <th>Description</th>
                                <th>Image URLs</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {books.map(book => (
                                <tr key={book.id}>
                                    <td>{book.title}</td>
                                    <td>{book.by}</td>
                                    <td><a href={book.link} target="_blank" rel="noopener noreferrer">Link</a></td>
                                    <td>{book.description}</td>
                                    <td>
                                        {book.images && book.images.map((imageUrl, index) => (
                                            <div key={index}>
                                                <a href={imageUrl} target="_blank" rel="noopener noreferrer">
                                                    Image {index + 1}
                                                </a>
                                            </div>
                                        ))}
                                    </td>
                                    <td>
                                        {/* Edit button */}
                                        <button onClick={() => this.toggleEditMode(book)}>Edit</button>
                                    </td>
                                    <td>
                                        {/* Delete button */}
                                        <button onClick={() => this.deleteBook(book.id)} style={{backgroundColor:'red'}}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No books available</p>
                )}

            </div>
        );
    }
}

export default AddBook;
