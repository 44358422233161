import React , {useState,useEffect } from 'react';
import HeaderPage from "../../components/secondary-hero-image/secondary_header_page";
import bgImage from '../../assets/magazines-bg.png'
import NavBar from "../../components/navbar/nav-bar";
import { Container, Row, Col } from 'react-bootstrap';
import magazines from '../../utils/magazines-data';
import { FaAngleDoubleDown } from "react-icons/fa";

function Magazines() {
    const [showArrow, setShowArrow] = useState(true);
    const handleScroll = () => {
        if (window.scrollY > 100) {
          setShowArrow(false);
        } else {
          setShowArrow(true);
        }
      };

      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return <>
        <HeaderPage image={bgImage} title="Magazines" path="HOME &gt; MEDIA &gt; Magazines" />
        <NavBar withBg={true} />
        <Container fluid style={{ paddingLeft: 60, paddingRight: 60, marginTop: 30 }}>
        <Row className="justify-content-center">
          {magazines.map((item, i) => (
            <Col xs={12} sm={6} md={4} lg={3} key={i} style={{ marginBottom: '20px' }}>
              <a href={`/magazines/${i}`} id="links" style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className="gallery-image" style={{ position: 'relative', overflow: 'hidden', height: '100%' }}>
                  <img 
                    alt={item.title} 
                    src={item.image} 
                    style={{ 
                      width: '100%', 
                      height: 'auto', 
                      maxHeight: '300px', 
                      objectFit: 'contain' 
                    }} 
                  />
                {/*  <div className="text-teko content">
                    <div className="card-title">{item.title}</div>
                    <div className="subtitle">{item.subtitle}</div>
                </div>*/}
                </div>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
      {showArrow && (
        <div style={{
            position: 'fixed',
            bottom: '20px',
            left: '50%',
          transform: 'translateX(-50%)',
          zIndex: '1000',
          cursor: 'pointer',
          animation: 'bounce 2s infinite',
          borderRadius:50,
          padding: 10,
        }}>
          <FaAngleDoubleDown size={45} color="black" />
        </div>
      )}
      <style jsx>{`
        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-10px);
          }
          60% {
            transform: translateY(-5px);
          }
        }
      `}</style>
    </>
}
export default Magazines;