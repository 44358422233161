import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaMotorcycle } from 'react-icons/fa';
import Button from '../../elements/button/button_element';
import TitleLeft from '../../elements/section-title/title-left';
import Numbers from '../numbers/numbers';
import { useNavigate } from 'react-router-dom';


import './explore-with-us-section.css'
const ExploreWithUs = () => { 
    const navigate = useNavigate();
    const handleClick = () => {
    navigate('/about-us/'); 
    };
    return <Container fluid className='top top-padding'>
        <Row>
            <Col className='chi-siamo-title' md={6}>
                <TitleLeft title="chi siamo" subtitle=" Esplora il mondo "/>
                <h1 className="title-tours text-teko" style={{ color: "#EB5223" }}>Con noi</h1>
                <p className='nanoSantaFont'>Secondo Platone, "conoscere" è "ricordare". Spesso i ricordi si tramutano in "nostalgia".Nostalgia è una parola formata dal prefisso "nostos", che vuol dire "ritorno" e dal suffisso algìa, ovvero "voce".Si tratta, quindi, di una designazione istituita per definire una patologia, una malattia che colpiva, spesso, i soldati svizzeri, quando questi venivano destinati presso guarnigioni straniere. E poi, in seguito, da patologia, con il passare delle epoche, è stata trasformata in un "sentimento". Ma va comunque ricordato</p>
                <Row style={{paddingBottom:60}}>
                    <Button width="170px" height="45px" text="RIGUARDO A NOI" icon={ <FaMotorcycle className='icon-button'/>} onClick={handleClick} />
                 </Row>
            </Col>
            <Col  md={6}>
                {/* Vedio  */}
                <iframe  
                    src="https://www.youtube.com/embed/94fiJTPFaPQ" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </Col>
        </Row>
        <Numbers fluid={true}/>
    </Container>
}
export default ExploreWithUs; 