import React, { useState, useEffect } from 'react';
import './books-list.css';
import BookCard from '../book-card/book-card';
import SectionTitle from '../../elements/section-title/section-title';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { fireStore } from '../../firebase_setup/firebase';

export default function BooksList() {
  const [books, setBooks] = useState([]);

  const fetchData = () => {
    fireStore.collection('books').onSnapshot(snapshot => {
      const booksData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setBooks(booksData);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="top-padding" style={{ margin: '30px 16px' , display:"flex", flexDirection:"column",alignContent:"center", justifyContent:"center"}}>
      <SectionTitle text='Leggi i libri' />
      <p className='takoFont bigTitle'>i miei libri</p>
      {books.length > 0 ? (
       <OwlCarousel
       className="owl-theme"
       items={4}
       
       nav
       responsive={{
         0: {
           items: 1,
         },
         576: {
           items: 2,
         },
         768: {
           items: 2,
         },
         992: {
           items: 3,
         },
         1200: {
           items: 4,
         }
       }}
     >
       {books.map((book, i) => (
         <BookCard
           image={book.images[0]}
           key={i}
           title={book.title}
           by={book.by}
           id={book.id}
         />
       ))}
     </OwlCarousel>
     
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
