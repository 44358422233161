import React from 'react';
import NavBar from "../../components/navbar/nav-bar"
import { Col, Container, Row } from 'react-bootstrap';
import VideoHeroImage from "../../components/video_hero_image/video_hero_image";
import locationIcon from '../../assets/locationIcon.svg'

import './style.css'

function Health() {

    return <>
        <NavBar withBg={false} />
        <div style={{ marginBottom: '120px' }}></div>

        <Container className='alltext'>
            <Row className='takoFont sub-title'>Il mal d’altura</Row>
            <Row>
                Durante un viaggio in Sud America, si raggiungono altezze considerevoli da non sottovalutare, quindi consultate sempre un medico prima di partire.
                Su qualsiasi guida, o online potrete trovare tutti i più comuni consigli per combattere il mal di soroche, cioè questo malessere dato dall’altitudine che si attenuerà man mano, giusto il tempo che il vostro corpo richiede per acclimatarsi. Quello che state leggendo, è stato estrapolato da un articolo scritto da un medico su internet.
                È importante tenere conto della capacità dell’individuo di reagire all’altitudine: ho visto gente non accusarne affatto, più frequentemente invece capita che si soffra di un mal di testa persistente che a volte fa fatica a sparire anche con l’aiuto dei comuni analgesici.
            </Row>



            <Row className='takoFont sub-title'>I sintomi più comuni sono</Row>
            <Row>
                <Col style={{ maxWidth: '30px' }}> <img alt='' src={locationIcon} className='locationIcon' /></Col>
                <Col md='11'>
                    Mal di testa e giramenti
                </Col>
            </Row>
            <Row>
                <Col style={{ maxWidth: '30px' }}> <img alt='' src={locationIcon} className='locationIcon' /></Col>
                <Col md='11'>
                    Nausea
                </Col>
            </Row>
            <Row>
                <Col style={{ maxWidth: '30px' }}> <img alt='' src={locationIcon} className='locationIcon' /></Col>
                <Col md='11'>
                    Aumento del battito cardiaco
                </Col>
            </Row>
            <Row>
                <Col style={{ maxWidth: '30px' }}> <img alt='' src={locationIcon} className='locationIcon' /></Col>
                <Col md='11'>
                    Gonfiore degli arti
                </Col>
            </Row>

            <Row>
                <Col style={{ maxWidth: '30px' }}> <img alt='' src={locationIcon} className='locationIcon' /></Col>
                <Col md='11'>
                    Mancanza di fiato e spossatezza.
                </Col>
            </Row>

            <Row>
                Importante è anche essere consapevoli della propria salute: solitamente si sconsigliano determinate altitudini a persone con pressione alta e problemi cardiaci.
            </Row>

            <Row>
                Quello che non tutti dicono, è che data la mancanza di umidità può succedere che il vostro naso si secchi formando delle fastidiose crosticine che se ne andranno solo scendendo d’altitudine. Ecco spiegato perché da queste parti i bambini e qualche volta anche gli adulti, hanno sempre le dita nel naso.
            </Row>
            <Row>
                I consigli per attenuare i sintomi del mal d’altitudine sono vari e io li ho provati quasi tutti.
            </Row>


            <Row>
                <Col style={{ maxWidth: '30px' }}> <img alt='' src={locationIcon} className='locationIcon' /></Col>
                <Col md='11'>
                    Prima di tutto il mate de coca, un infuso di erbe di coca che vi rifileranno per tutta la durata del vostro viaggio fra le Ande. Sinceramente non so dirvi quanti benefici io abbia tratto dal famoso infuso di coca, ma quel che vi raccomando è di non farne mai a meno. Ad altezze elevate c’è davvero bisogno di idratarsi, quindi mettetela in questo modo: male non fa di certo.
                    In Peru la coca è legale: la troverete sotto forma di infusi, caramelle e foglie da masticare.
                </Col>
            </Row>

            <Row>
                <Col style={{ maxWidth: '30px' }}> <img alt='' src={locationIcon} className='locationIcon' /></Col>
                <Col md='11'>
                    A mio parere il terzo modo è quello più efficace e in tempi più brevi, però fatevi aiutare da un locale che vi spiegherà come fare. Se vi state chiedendo se questa causi dipendenza, la risposta è no!
                </Col>
            </Row>
            <Row>Infuso di coca per il mal di soroche</Row>
            <Row>Vi capiterà di vedere molti peruviani con un bozzetto agli angoli della bocca, stanno masticando coca e se sarete fortunati come lo sono stata io, vi regaleranno un bel sorriso a 32 denti e qualche foglia spezzettata.</Row>
            <Row>Non stupitevi e non guardateli come se fossero degli invasati, qui la coca ha molti significati ed è usata anche in molti rituali fin dai tempi degli Inca. Questa viene considerata una ricchezza e viene donata alla Pacha Mama, la Madre Terra, per renderle grazie per la sua fertilità. La coca è stata utilizzata per millenni nella medicina tradizionale andina. E’ molto efficace come anestetico ed è utilizzata addirittura come afrodisiaco.</Row>
            <Row>Un altro metodo per contrastare il mal d’altitudine è sicuramente l’ossigeno: molti alberghi e a volte anche i negozi ne sono provvisti. Se vi dovesse capitare di sentirvi poco bene, 5 minuto d’ossigeno possono bastare. Non di più, perché mi è stato spiegato che il corpo deve adattarsi naturalmente.</Row>
            <Row>È importante, inoltre, salire gradualmente d’altitudine, iniziando da circa 2500 mt a salire. Non dormire mai più in alto dei livelli che si sono toccati durante la giornata.</Row>
            <Row>Le farmacie sono molto fornite, potrete trovare delle pillole per il mal d’altitudine, le sorojchi pills, vendute per pochi soles (la moneta locale) senza bisogno di ricetta medica. Io ne ho prese un paio e sono tornata come nuova. Niente di illegale, ribadiamo</Row>
            <Row>Altro rimedio da non sottovalutare è il riposo, in assoluto il consiglio che vi raccomando di seguire: movimenti lenti, pochissimi sforzi, cibo leggero per favorire la digestione (no a verdure crude, salse, cibi piccanti).</Row>
            <Row>In qualsiasi tour del Perù è possibile notare l’inserimento nel programma delle tipiche mezze giornate a disposizione per il riposo, cosa che prima di arrivare non avevo preso benissimo ma che poi sono state davvero utili per la mia totale ripresa. Se doveste fare il tour del Perù in indipendenza, vi consiglio di ascoltare il vostro corpo e seguire i vostri ritmi.</Row>
            <Row>Un ultimo consiglio, questo totalmente personale e al di fuori di ciò che potrete leggere in rete, è quello di non farvi troppi castelli in aria su quello che potrebbe provocarvi l’altitudine:
                il fattore psicologico spesso incide fortemente e accentua i sintomi di un qualcosa che se non fosse risaputo, risulterebbe solo come spossatezza e un po’ di mal di testa.</Row>
            <Row>Godetevi le meraviglie delle Ande, bellezze senza paragoni.</Row>
            <Row>Molti viaggi in zone geograficamente particolari prevedono rapide salite in quota senza poter dare all'organismo il tempo di acclimatarsi. Sono per lo più salite in jeep o aereo, ma non solo. L'esempio più tipico è rappresentato dall'altopiano tibetano o dale Ande dove si passa in poche ore dai 2000 ai 5000 metri. Un buon acclimatamento è caratterizzato dall'assenza di disturbi e da un buon sonno notturno. Le urine devono essere chiare e abbon- danti. Se è presente anche solo un leggero mal di testa o un pò d'insonnia già significa che non vi state acclimatando bene. Se c'è anche inappetenza, nausea o vomito si può parlare a tutto diritto di mal di montagna! E' fondamentale dare importanza a questi primissimi sintomi senza accampare scuse. Cosa devono fare i viaggiatori che vanno in quota, magari dovendo anche salire rapidamente? Li dividerei in tre gruppi, LA TRIADE</Row>
            <Row>
                <Col style={{ maxWidth: '30px' }}> <img alt='' src={locationIcon} className='locationIcon' /></Col>
                <Col md='11'>
                    II primo comprende coloro che sono già stati molto in alto e non hanno avuto il benché minimo problema. Sono dei fortunati "rapi- di acclimatatori" ai quali riservo solo i consigli generici validi per tutti: non bere alcolici, assumere molti liquidi, non sforzarsi trop- po nei primi giorni. Sono solo il 25% della popolazione.                </Col>
            </Row>

            <Row>
                <Col style={{ maxWidth: '30px' }}> <img alt='' src={locationIcon} className='locationIcon' /></Col>
                <Col md='11'>
                    II secondo e più cospicuo gruppo è rappresentato da quei viaggiatori che hanno già sperimentato il malessere della quota. Magari solo un pò di mal di testa, d'insonnia o peggio di nausea o di stanchezza eccessiva, uniti ad uno strano gonfiore a mani e viso...Non trascurate questi primi sintomi, tutti infatti sono capaci di farvi notare che state male quando ormai vomitate e procedete barcol- lando. Questi "lenti acclimatatori" dovrebbero aiutarsi iniziando a prendere il Diamox almeno 24 ore prima del balzo in quota (una compressa il mattino ed una la sera x 5/6 giorni. Il dosaggio ridotto di 1/2 cpr per due volte al dì è a mio avviso meno efficace). Si tratta di un blando diuretico che, in questo caso, non si usa come tale, ma per il suo collaterale effetto acidificante che contrasta l'alcalosi, sempre presente in alta quota. E' un prodotto innocuo che può causarvi solo un pò di formicolio alle dita, ma che vi aiu- terà molto. Se ne deve astenere solo chi è sicuramente allergico ai sulfamidici. Si può associare ad altri farmaci come antimalarici, antibiotici ed analgesici/antinfiammatori.
                </Col>
            </Row>
            <Row>
                <Col style={{ maxWidth: '30px' }}> <img alt='' src={locationIcon} className='locationIcon' /></Col>
                <Col md='11'>
                    Infine il terzo gruppo è rappresentato da tutti quelli che non sono mai saliti molto e non sanno se staranno male oppure no. Purtroppo non ci sono sistemi semplici per prevederlo. Questi viaggiatori potranno stare a vedere cosa succede ed iniziare il Diamox (a dosaggio pieno) non appena compaiono i primissimi sintomi. Il benefìcio ci sarà comunque, ma sarà meno evidente e rapido che con la prevenzione. Oppure, se temono di star male o non vogliono correre il rischio di rovinarsi il viaggio, potranno iniziare a prendere il prodotto a dosaggio ridotto per passare eventualmente a quello pieno sempre alla comparsa dei primissimi sintomi. A conferma del grande aiuto che offre questo farmaco cito un caso che ho osservato di frequente. Partecipanti che inizia- no a prenderlo e stando bene lo sospendono dopo un paio di giorni. Solo per affrettarsi a riassumerlo per il grande disagio che segue la sospensione!                                </Col>
            </Row>

            <Row>Ricordo che né la forma fìsica, né l'allenamento, né le ascenzioni precedenti migliorano la capacità di adattarsi all'altitudine. Tale capacità sembra essere geneticamente determinata, un pò come il colore degli occhi. Inoltre il malessere della quota non compare mai appena arrivati, ma sempre dopo 6/12 o più ore dopo. Ho purtroppo notato che, forse per la fretta di continuare o per il timore di arrecare problemi al gruppo, quasi tutti tendono a sottovalutare e negare le sofferenze della quota adducendo sempre le stesse scusebanali. Per dirimere ogni dubbio e non dare spazio alle scuse, ho ideato una "triade" da prendere alla lettera In quota qualsiasi mal di testa è solo dovuto all'altitudine (anche se vi siete cotti al sole ed avete pure preso una randellata sulla nuca) In quota la prostrazione e l'insonnia è solo dovuta all'altitudine (anche se avete corso in salita bevendo thè e caffè), In quota la nausea/vomito è solo dovuta all'altitudine. Questo semplice manuale è stato scritto da un medico che da tempo si occupa di problemi di montagna. Purtroppo è un "acclimatatore lento". Ha così condiviso le pro- prie sofferenze con numerosi partecipanti ai viaggi in quota ed ha sperimentato insieme a loro quelle strategie atte a rendere l'acclima- tamento meno penoso. Date retta alla "triade" e se riconoscete anche solo uno dei sintomi, non accampate scuse, magari non dite nulla agli altri, ma passate subito al Diamox a dosaggio pieno (eventualmente associandolo ad un antidolorifico).</Row>

        </Container>
        <div style={{ marginBottom: '50px' }}></div>
        <VideoHeroImage />
    </>
}
export default Health