import React, { useState, useEffect } from 'react';

import "bootstrap/dist/css/bootstrap.css";
import logo from "../../assets/inmoto-logo.png";
import "./nav-bar.css";
import locationIcon from '../../assets/locationIcon.svg'
import facebookIcon from '../../assets/icons/facebookIcon.svg'
import instagramIcon from '../../assets/icons/instagramIcon.svg'
import youtubeIcon from '../../assets/icons/youtubeIcon.svg'
import messangerIcon from '../../assets/icons/messangerIcon.svg'
import emailIcon from '../../assets/icons/emailIcon.svg'
import whatsappIcon from '../../assets/icons/whatsappIcon.svg'
import formLiability from '../../utils/scheda-iscrizione-scarico-responsabilita-2024.pdf';
import contactCondition from '../../utils/Condizioni_generali_di_contratto_INMOTOVUNQUE_NUOVO_ul.pdf';


import { Container, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import { FaBars, FaTimes, } from 'react-icons/fa';

const NavBar = ({ withBg }) => {
  let bgClassName = withBg ? 'nav-bar-bg-white' : 'nav-bar-bg';
  const [click, setClick] = useState(false);
  const closeMobileMenu = () => setClick(false);
  const [isScrolled, setIsScrolled] = useState(false);


  const handleClick = () => {
    setClick(!click);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClasses = `navbar ${isScrolled ? "scrolled" : ""}`;


  return (
    <>
      <nav className="navbar navbar-expand-sm fixed-top navbar-light bg-dark social-links-navbar">
        <Container fluid>
          <Col>
            <img alt='' src={emailIcon} className="icon" />
            info@inmotovunque.com
            <img alt='' src={locationIcon} />
            <img alt='' src={whatsappIcon} className="icon" />
            +39 3426314585
            <img alt='' src={locationIcon} />
            <a href="https://www.instagram.com/inmotoovunque/" target="_blank" rel="noopener noreferrer"><img alt='instagram' src={instagramIcon} className="icon" /></a>
            <a href="https://youtube.com/@inmotovunque?feature=shared" target="_blank" rel="noopener noreferrer"><img alt='youtube' src={youtubeIcon} className="icon" /></a>
            <a href="https://m.me/inmotovunquenwse/" target="_blank" rel="noopener noreferrer"> <img alt='messenger' src={messangerIcon} className="icon" /></a>
            <a href="https://www.facebook.com/inmotovunquenwse" target="_blank" rel="noopener noreferrer"><img alt='facebook' src={facebookIcon} className="icon" /></a>
          </Col>
        </Container>
      </nav>
      <nav className={"navbar navbar-expand-sm fixed-top  NavbarItems" + bgClassName + navbarClasses} style={{ marginTop: '40px', height: '10%', paddingTop: '2%' }}>
        <div className="container-fluid">

          <a href="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img alt='' src={logo} />

          </a>

          <div className="menu-icon" onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item" >

              <a onClick={closeMobileMenu} className="nav-link" href="/" id="links"> <p>HOME</p>
                <img alt='' src={locationIcon} className='locationIcon' />

              </a>
            </li>

            <li className="nav-item" >

              <a onClick={closeMobileMenu} className="nav-link" href="/about-us" id="links"> <p>CHI SIAMO</p>   <img alt='' src={locationIcon} className='locationIcon' />
              </a>
            </li>

            <li className="nav-item" >

              <a onClick={closeMobileMenu} className="nav-link" href="/tours" id="links"> <p> TOURS</p>
                <img alt='' src={locationIcon} className='locationIcon' />

              </a>
            </li>

            <DropdownButton id="links" title="MEDIA" className="dropdown-button nav-item media" style={{ paddingTop: "3%" }}>
              <Dropdown.Item href="/photos">
                <a onClick={closeMobileMenu} className="nav-link" href="/photos" id="links">  <p>  Gallery</p>
                </a>
              </Dropdown.Item>
              <Dropdown.Item href="/books" >
                <a onClick={closeMobileMenu} className="nav-link" href="/books" id="links">  <p>  BOOKS</p>
                </a>
              </Dropdown.Item>
              <Dropdown.Item  href="/magazines">
                <a onClick={closeMobileMenu} className="nav-link" href="/magazines" id="links">  <p>  Magazines</p>
                </a>
              </Dropdown.Item>
            </DropdownButton>
            <li className="nav-item" >
              <img alt='' src={locationIcon} className='locationIcon' style={{ marginTop: '25px' }} />
            </li>
            <DropdownButton id="links" title="UTILITA" className="dropdown-button nav-item media" style={{ paddingTop: "3%" }}>
              <Dropdown.Item href="/traveling-by-motorbike">
                <a onClick={closeMobileMenu} className="nav-link" href="/traveling-by-motorbike" id="links">  <p>  Viaggiare  in moto</p>
                </a>
              </Dropdown.Item>
              <Dropdown.Item href="/suggestions">
                <a onClick={closeMobileMenu} className="nav-link" href="/suggestions" id="links">  <p>  Suggerimenti</p>
                </a>
              </Dropdown.Item>
              <Dropdown.Item href="/desert">
                <a onClick={closeMobileMenu} className="nav-link" href="/desert" id="links">  <p>  IL DESERTO</p>
                </a>
              </Dropdown.Item>
              <Dropdown.Item href="/shipment">
                <a onClick={closeMobileMenu} className="nav-link" href="/shipment" id="links">  <p>  La spedizione della moto</p>
                </a>
              </Dropdown.Item>
              <Dropdown.Item href="/health">
                <a onClick={closeMobileMenu} className="nav-link" href="/health" id="links">  <p>  Il mal d’ALTURA</p>
                </a>
              </Dropdown.Item>
              <Dropdown.Item href={contactCondition}>
                <a onClick={closeMobileMenu} className="nav-link" href={contactCondition} id="links" target="_blank" rel="noopener noreferrer">  <p>  Condizioni generali di contratto </p>
                </a>
              </Dropdown.Item>
     
              <Dropdown.Item href={formLiability}>
                <a onClick={closeMobileMenu} className="nav-link" href={formLiability} id="links" target="_blank" rel="noopener noreferrer">  <p>  Scheda Di Discrizione </p>
                </a>
              </Dropdown.Item>
              <Dropdown.Item href="/termsAndPolicies" >
                <a onClick={closeMobileMenu} className="nav-link" href="/termsAndPolicies" id="links">  <p>  Terms & Policy </p>
                </a>
              </Dropdown.Item>
              <Dropdown.Item href="/assicurazioni" >
                <a onClick={closeMobileMenu} className="nav-link" href="/assicurazioni" id="links">  <p>  Assicurazioni </p>
                </a>
              </Dropdown.Item>
            </DropdownButton>
            

            <li className="nav-item" >

              <a onClick={closeMobileMenu} className="nav-link" href="/blog" id="links">
                <img alt='' src={locationIcon} className='locationIcon' />
                <p>  BLOG</p>

              </a>
            </li>

            <li className="nav-item  contact">

              <a onClick={closeMobileMenu} className="nav-link" href="/contact" id="links" style={{color:'white'}}> CONTATTI
              </a>
            </li>
          </ul>
        </div>
      </nav >
    </>
  );
};
export default NavBar;
