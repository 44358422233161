import React from 'react';
import HeaderPage from "../../components/secondary-hero-image/secondary_header_page";
import toursBg from '../../assets/tours-bg.png'
import NavBar from "../../components/navbar/nav-bar";
import ToursList from "../../components/tours-list/tours-list";

const Tours = () => {
    return <>
        <HeaderPage image={toursBg} title="Sui prossimi viaggi" path="HOME &gt; Tours" />
        <NavBar withBg={true} />
        <ToursList/>
    </>
}
export default Tours;