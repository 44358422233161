import React from 'react';
import './button_element.css'
const Button = ({width, height,text, icon, onClick}) => {
    return (
        <div className='button-section text-teko'>
            <button type="submit" className="btn button" style={{width:width, height: height,color:"#ffff"
            }}  onClick={onClick} >
                 {text}&ensp;| {icon}
            </button>
        </div>
    )
}
export default Button; 