import React, { useEffect, useState } from 'react';
import HeroImage from "../../components/primary_header/primary_header";
import UpcomingEvent from "../../components/countdown/upcoming-events";
import GetLastCatalogForm from "../../components/get-catalog-form-section/get-catalog-form-section";
import ToursSection from "../../components/tours-section/tours-section";
import BooksList from '../../components/books-list/books-list.jsx';
import ExploreWithUs from "../../components/explore-with-us/explore-with-us-section";
import Gallery from "../../components/gallery/gallery";
import OurTeam from "../../components/our-team-section/our_team";
import VideoHeroImage from "../../components/video_hero_image/video_hero_image";
import NavBar from "../../components/navbar/nav-bar";
//import Map from "../../components/map/map";
import { fireStore } from '../../firebase_setup/firebase';

const  Home =()=> {
  const [nextEvent, setNextEvent] = useState({
    image: "",
    title: '',
    subtitle: '',
    nextEventDate: '',
    details:""
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from Firestore and listen for real-time updates
        const unsubscribe = fireStore.collection('tours').onSnapshot(snapshot => {
          const toursData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })).filter(tour => !tour.disabled);
  
          if (toursData.length === 0) {
            // Handle the case when there are no tours
            console.log("No tours available");
            return;
          }
  
          // Find the tour with the nearest future date
          const currentDate = new Date();
          const validTours = toursData.filter(tour => new Date(tour.startDate) > currentDate);
  
          if (validTours.length === 0) {
            // Handle the case when there are no valid tours
            console.log("No valid tours available");
            return;
          }
  
          const nearestTour = validTours.reduce((prev, current) => {
            const currentDateDiff = Math.abs(currentDate.getTime() - new Date(current.startDate).getTime());
            const prevDateDiff = Math.abs(currentDate.getTime() - new Date(prev.startDate).getTime());
            return currentDateDiff < prevDateDiff ? current : prev;
          });
  
          // Update the nextEvent state with the tour having the nearest date
          setNextEvent({
            id: nearestTour.id,
            image: nearestTour.image,
            title: nearestTour.title,
            subtitle: nearestTour.subTitle,
            nextEventDate: nearestTour.startDate,
            details: nearestTour.details
          });
        });

        // Cleanup function
        return unsubscribe;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []);

  return (
    <>
      <HeroImage />
      <NavBar />
      <UpcomingEvent nextEvent={nextEvent} />
      <GetLastCatalogForm />
      <ExploreWithUs />
      <ToursSection />
      <OurTeam/>     
      <BooksList />
      <Gallery/>
     {/* <Map nextEvent={nextEvent}/>*/}
      <VideoHeroImage />
    </>
  );
}

export default Home;
