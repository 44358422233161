import React, { useState } from 'react';
import './primary_header.css';
import video from '../../assets/v_hero.mp4';
import { GoUnmute } from "react-icons/go";
import { GoMute } from "react-icons/go";


export default function HeroImage() {
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };
  return (
    <header className="hero-video">
      <video autoPlay loop muted={isMuted} className="video-content">
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button onClick={toggleMute} className="mute-btn">
        {isMuted ?  <GoMute/> : <GoUnmute/>}
      </button>
    </header>
  );
}