import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { FaRegEye } from 'react-icons/fa';
import Button from '../../elements/button/button_element';
import TitleLeft from '../../elements/section-title/title-left';
import './our_team.css';
import TeamCard from '../team-card/team-card.jsx'
import team1 from '../../assets/team/team1.png';
import team2 from '../../assets/team/team2.png';

const OurTeam = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/about-us/');
    };

    return <Container fluid className="top-padding">
        <Row>
            <Col md={4} className='our-team-section'>
                <TitleLeft title="Il team" subtitle="Meravigliose persone dietro a noi" className="title-gallery" />
                <Button type="button" height="240" width="518" text="Tutti i membri" icon={<FaRegEye className='icon-button' />} onClick={handleClick} />

            </Col>
            <Col md={4} style={{ marginBottom: '20px' }} >
                <TeamCard memberName='Ciocio Cavallo' role_tc='founder & Tour Leader' image={team1} />
            </Col>
            <Col md={4} style={{ marginBottom: '20px' }} >
                <TeamCard memberName='Asia Cavallo' role_tc='Translator' image={team2} />
            </Col>
        </Row>
    </Container>
}
export default OurTeam;