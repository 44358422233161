import React from "react";
import { Link } from 'react-router-dom';

const AdminGallery = function () {
    let galleryImage = [
        {
            title: "Tunisia",
        },
        {
            title: "Marroco",
        },
        {
            title: "Turchia",
        },
        {
            title: "Patagonia",
        },
        {
            title: "Over andes peru bolivia",
        },
        {
            title: "Oman",
        },
        {
            title: "Dakar",
        },
        {
            title: "Australia",
        },
        {
            title: "Canada Alaska",
        }
    ]
    return (<div>
        <h1 style={{ margin: '50px' }}>Gallery</h1>
        <table className='container' style={{ margin: '50px' }}>
            <thead>
                <tr>
                    <th>Gallery</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {galleryImage.map((album, index) => (
                    <tr key={index}>
                        <td>{album.title}</td>
                        <td>
                            <Link to={`/admin/gallery/${album.title}`}>Edit</Link>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>)
}

export default AdminGallery;