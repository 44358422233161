import React from 'react';
import { fireStore } from '../../../firebase_setup/firebase';
import './style.css';

class AddTestimonials extends React.Component {
    constructor() {
        super();
        this.state = {
            from: '',
            message: '',
            title: '',
            testimonials: [],
            isEditing: false,
            editId: null,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        let allData = [];
        fireStore.collection("testimonials").get().then((querySnapshot) => {
            querySnapshot.forEach(element => {
                var data = element.data();
                allData.push({
                    id: element.id,
                    from: data.from,
                    message: data.message,
                    title: data.title,
                });
            });
            this.setState({ testimonials: allData });
        });
    }

    updateInput = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleFormSubmit = e => {
        e.preventDefault();
        const { from, message, title, isEditing, editId } = this.state;

        if (isEditing) {
            this.updateTestimonial(editId, { from, message, title });
        } else {
            this.addTestimonial({ from, message, title });
        }
    }

    addTestimonial = testimonial => {
        fireStore.collection('testimonials').add(testimonial)
            .then(() => {
                this.setState({ from: '', message: '', title: '' });
                this.fetchData();
            })
            .catch(error => console.error('Error adding document: ', error));
    };

    updateTestimonial = (id, updatedTestimonial) => {
        fireStore.collection('testimonials').doc(id).update(updatedTestimonial)
            .then(() => {
                this.setState({ from: '', message: '', title: '', isEditing: false, editId: null });
                this.fetchData();
            })
            .catch(error => console.error('Error updating document: ', error));
    };

    handleEditTestimonial = (testimonial) => {
        this.setState({
            from: testimonial.from,
            message: testimonial.message,
            title: testimonial.title,
            isEditing: true,
            editId: testimonial.id
        });
    };

    onDelete = (id, e) => {
        e.preventDefault();
        fireStore.collection('testimonials').doc(id).delete()
            .then(() => this.fetchData())
            .catch(error => console.error('Error deleting document: ', error));
    }

    render() {
        return (
            <div style={{ padding: '24px' }}>
                <form onSubmit={this.handleFormSubmit} style={{ width: '500px' }}>
                    <input
                        type="text"
                        name="from"
                        placeholder="from"
                        onChange={this.updateInput}
                        value={this.state.from}
                    />
                    <input
                        type="text"
                        name="title"
                        placeholder="Tours"
                        onChange={this.updateInput}
                        value={this.state.title}
                    />
                    <textarea
                        name="message"
                        placeholder="message"
                        onChange={this.updateInput}
                        value={this.state.message}
                    />
                    <button type="submit">{this.state.isEditing ? 'Update' : 'Add'}</button>
                </form>
                <h2>Testimonials</h2>
                <table>
                    <thead>
                        <tr>
                            <th>From</th>
                            <th>Tours</th>
                            <th>Message</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.testimonials.map((testimonial) => (
                            <tr key={testimonial.id}>
                                <td>{testimonial.from}</td>
                                <td>{testimonial.title}</td>
                                <td>{testimonial.message}</td>
                                <td className="action-buttons">
                                    <button onClick={() => this.handleEditTestimonial(testimonial)}>Edit</button>
                                    <button className="delete" onClick={(e) => this.onDelete(testimonial.id, e)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AddTestimonials;
