import React from 'react';
import NavBar from "../../components/navbar/nav-bar"
import { Col, Container, Row } from 'react-bootstrap';
import cover from '../../assets/travelingbymoto/travelingbymoto1.png'
import img1 from '../../assets/travelingbymoto/travelingbymoto2.png'
import img2 from '../../assets/travelingbymoto/travelingbymoto3.png'
import signature from '../../assets/utils/signature.png'
import VideoHeroImage from "../../components/video_hero_image/video_hero_image";

import './style.css'
function TravelingByMotorbike() {
    return <>
        <NavBar withBg={false} />
        <div style={{ marginBottom: '150px' }}></div>
        <Container > <img alt='utilita' src={cover} className='cover'></img></Container>
        <Container className='alltext'>
            <Row>
                Viaggiare in moto può essere un divertimento, una vacanza, una professione o semplicemente una grande passione. Un viaggio in moto, spesso viene raccontato, mostrato, condiviso, io, dal canto mio, ho sempre cercato di fare di un viaggio in moto solamente un’esperienza, anche adesso che il viaggio in moto è per me una professione. Il viaggio in moto è diverso da tutti gli altri, sicuramente è il più scomodo, ma è un avventura, sempre, piccola o grande, non fa nessuna differenza. Le cose che osservi dietro la visiera del casco, non sono in sé, ne belle nè brutte, è il tuo giudizio su di esse che conferisce loro un valore.
            </Row>
            <Row>
                La moto, e solo la Tua moto, è la “conditio sine qua non” perché Tu debba iniziare a pensare ad un viaggio. Perché solo con la Tua moto? è semplice:
            </Row>
            <br />
            <Row>
                <p className='message nanoSantaFont'><samp className='comma'>“</samp>Un viaggio in moto parte sempre da un idea, che risulta essere la cosa più difficile, avuta l’idea, tutto il resto è semplice, la preparazione del mezzo, per tutti diversa e personale viene pianificata a seconda del viaggio che si farà. Il pensare dove attaccare una tanica supplementare utile in quel tratto dove sappiamo non esserci benzina, il supporto del GPS, il copertone di ricambio, le borse da portare fanno sì che il viaggio cominci molto prima con sensazioni che accorciano la data di partenza, solo la Tua moto, spedita dall’altra parte del mondo, ti provocherà queste emozioni.</p>
            </Row>
            <Row>
                Non sono necessarie moto “Full Desert Optional“ in pendant di colori, tirate a lucido, sono necessarie solo moto ben controllate e messe a punto, l’aria in faccia è sempre la stessa……. Sia con una moto nuova che con una vecchia.
                Non bisogna essere dei Superman per poter fare un viaggio in moto, ma bisogna avere poche e semplici caratteristiche, fondamentali a seconda se si viaggia in gruppo o da soli, e soprattutto dal tipo di viaggio che si vuole affrontare, vediamole:
            </Row>
            <Row className='takoFont sub-title'>LA RESILIENZA</Row>
            <Row>
                la capacità di trasformare un evento negativo, frequente in moto, anche solo se si va al mare o in montagna, in una cosa positiva, costruttiva, da raccontare al ritorno.
            </Row>
            <Row className='takoFont sub-title'>LO SPIRITO DI GRUPPO</Row>
            <Row>
                la capacità di sapersi adeguare, senza essere condizionati, alle esigenze di chi viaggia con Te.
            </Row>
            <Row className='takoFont sub-title'>LO SPIRITO DI ADATTAMENTO</Row>
            <Row>
                la capacità di essere flessibile, malleabile, un viaggio in moto è spesso fatto di imprevisti, specie se ti trovi dalla parte opposta di dove vivi, condizioni climatiche, ritardi, logistica sono fattori che non si risolvono con la polemica.  </Row>
            <Row className='takoFont sub-title'>LO SPIRITO DI SACRIFICIO</Row>
            <Row>
                la capacità di essere altruista, generoso nel condividere i problemi con i compagni di viaggio, cercando di essere costruttivo. </Row>

            {/* <Row className='takoFont sub-title'>
                Un viaggio in moto parte sempre da un idea, che risulta essere la cosa più difficile, avuta l’idea, tutto il resto è semplice, la preparazione del mezzo, per tutti diversa e personale viene pianificata a seconda del viaggio che si farà. Il pensare dove attaccare una tanica supplementare utile in quel tratto dove sappiamo non esserci benzina, il supporto del GPS, il copertone di ricambio, le borse da portare fanno sì che il viaggio cominci molto prima con sensazioni che accorciano la data di partenza, solo la Tua moto, spedita dall’altra parte del mondo, ti provocherà queste emozioni.
            </Row> */}

            <Container >
                <Row>
                    <Col md='4' ><img alt='utilita' src={img1} className='cover'></img></Col>
                    <Col><img alt='utilita' src={img2} className='cover'></img></Col>
                </Row>
            </Container>
            <Row>Se non hai queste qualità, parti da solo, viaggerai sicuramente più veloce, ma forse farai meno strada e perderai un momento magico, la condivisione e le diverse prospettive di cosa si è visto sarà il bagaglio più bello che porterai a casa, il bollo sul serbatoio o la riga su un bauletto, non toglierle, saranno il ricordo di una splendida avventura.</Row>
            <Row>  <img alt='utilita' src={signature} className='cover'></img></Row>
        </Container>
        <div style={{ marginBottom: '50px' }}></div>
        <VideoHeroImage />
    </>
}
export default TravelingByMotorbike