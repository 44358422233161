import React, { useEffect, useState } from 'react';
import NavBar from "../../components/navbar/nav-bar";
import HeaderPage from "../../components/secondary-hero-image/secondary_header_page";
import bgImage from '../../assets/blog-cover.png';
import Testimonial from '../../components/testomanials/testimonial';
import VideoHeroImage from "../../components/video_hero_image/video_hero_image";
import { Container, Pagination, Dropdown } from 'react-bootstrap';
import { fireStore } from '../../firebase_setup/firebase';
import "./blog.css"

function Blog() {
  const [testimonials, setTestimonials] = useState([]);
  const [filteredTestimonials, setFilteredTestimonials] = useState([]);
  const [filterTestimonials, setFilterTestimonials] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState('');

  const itemsPerPage = 10;

  // Calculate the indexes for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let allData = [];
        let filterData = [];
        const testimonialSnapshot = await fireStore.collection("testimonials").get();
        testimonialSnapshot.forEach(element => {
          var data = element.data();
          allData.push(data);
        });
        const filterSnapshot = await fireStore.collection("filter_testimonials").get();
        filterSnapshot.forEach(element => {
          var data = element.data().tour;
          filterData.push(...data);
        });
        setTestimonials(allData);
        setFilteredTestimonials(allData);
        setFilterTestimonials(filterData);
        console.log('Testimonials:', allData);
        console.log('Filter Testimonials:', filterTestimonials);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  

  // Filter testimonials based on selected filter
  useEffect(() => {
    if (selectedFilter) {
      const filtered = testimonials.filter(testimonial =>
        testimonial.title.toLowerCase() === selectedFilter.toLowerCase()
      );
      setFilteredTestimonials(filtered);
    } else {
      setFilteredTestimonials(testimonials);
    }
  }, [selectedFilter, testimonials]);

  // Get the testimonials for the current page
  const currentTestimonials = filteredTestimonials.slice(startIndex, endIndex);

  // Function to handle pagination click
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to handle filter change
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    setCurrentPage(1);
  };

  return (
    <>
    <HeaderPage image={bgImage} title="Blog" path="HOME &gt; Blog" />
    <NavBar withBg={true} />
    <Container fluid style={{paddingLeft:60, paddingRight:60}}>
    <Dropdown className="filter-button"  style={{ marginTop:16}}> 
    <Dropdown.Toggle className="dropdown-toggle-custom">
        <p className='text-teko' style={{paddingTop:16}}>Seleziona filtro</p> 
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filterTestimonials.map((filter, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => handleFilterChange(filter)}>
            {filter}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>

      {currentTestimonials.map((testimonial, i) => (
        <Testimonial key={i} title={testimonial.title} message={testimonial.message} from={testimonial.from} />
      ))}
      <Pagination className="justify-content-center">
        {Array.from({ length: Math.ceil(filteredTestimonials.length / itemsPerPage) }, (_, i) => (
          <Pagination.Item
            key={i}
            active={i + 1 === currentPage}
            onClick={() => handlePageChange(i + 1)}
            className={i + 1 === currentPage ? 'active-pagination' : ''}
          >
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </Container>
    <VideoHeroImage />
  </>
  );
}

export default Blog;
