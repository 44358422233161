import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { storage, firebaseConfig } from '../../../firebase_setup/firebase';
import firebase from 'firebase/compat/app';
import ImageViewer from 'react-simple-image-viewer';
import { useLocation } from 'react-router-dom';
import { FaTrashAlt } from "react-icons/fa";

function AdminAlbum() {
    const [photoUrls, setPhotoUrls] = useState([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const tour = useLocation().pathname.split('/')[3];

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    useEffect(() => {
        firebase.initializeApp(firebaseConfig);
        storage.ref()
            .child('/' + tour.toLocaleLowerCase())
            .listAll()
            .then((res) => {
                const promises = res.items.map((itemRef) =>
                    itemRef.getDownloadURL()
                );
                Promise.all(promises)
                    .then((urls) => {
                        const formattedUrls = urls.map((url) => (url));
                        setPhotoUrls(formattedUrls);
                    })
                    .catch((error) => {
                        console.error('Error retrieving photo URLs:', error);
                    });
            })
            .catch((error) => {
                console.error('Error retrieving photos:', error);
            });
    }, []);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    const onDelete = async (url) => {
        try {
            // Get a reference to the file using the URL
            const imageRef = storage.refFromURL(url);
            // Delete the file
            await imageRef.delete();

            console.log('Image deleted successfully');
            window.location.reload(true);

        } catch (error) {
            console.error("Error deleting image:", error);
        }
    }

    const uploadImage = async (file) => {
        try {
            // Create a reference to the storage path where you want to store the image
            const storageRef = storage.ref(`${tour.toLocaleLowerCase()}/${file.name}`);

            // Upload the file to the specified path
            const snapshot = await storageRef.put(file);

            console.log('Image uploaded successfully:', snapshot.ref.fullPath);

            // Return the URL of the uploaded image
            return snapshot.ref.getDownloadURL();
        } catch (error) {
            console.error('Error uploading image:', error.message);
            throw error;
        }
    };
    const handleUploadImage = async () => {
        if (selectedImage) {
            try {
                // Upload the selected image
                const imageUrl = await uploadImage(selectedImage);
                console.log('Uploaded image URL:', imageUrl);
                window.location.reload(true);

                // Handle further actions with the imageUrl if needed
            } catch (error) {
                // Handle the error, if any
            }
        }
    };
    return (
        <>
            <Container fluid >

                <Row style={{ margin: '60px' }}>
                    <Row> Please choose the image and then click upload the image button: </Row>
                    <Col> <input type="file" onChange={handleFileChange} /></Col>
                    <Col><button onClick={handleUploadImage}>Upload Image</button></Col>
                </Row>
                <Row style={{ marginTop: '10px', marginLeft: '50px' }}>
                    {photoUrls.map((photo, i) =>
                        <div key={i} style={{ height: '150px', width: '300px' }}> <Row onClick={() => openImageViewer(i)} style={{ backgroundImage: `url(${(photo)})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '100px', width: '200px', margin: '5px', cursor: 'pointer' }}>
                        </Row>
                            <FaTrashAlt style={{ width: '40px', height: '20px', cursor: 'pointer' }} onClick={() => onDelete(photo)} />
                        </div>
                    )}
                    {isViewerOpen && (<Container fluid >
                        <ImageViewer
                            src={photoUrls}
                            currentIndex={currentImage}
                            disableScroll={false}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                            backgroundStyle={{
                                backgroundColor: "rgba(0,0,0,0.6)"
                            }}
                        /></Container>)}
                </Row>
            </Container>
        </>
    );
};

export default AdminAlbum;


