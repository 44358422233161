import React, { useState } from 'react';

import { Col, Container, Row } from "react-bootstrap";
import locationIcon from '../../assets/locationIcon.svg'
import ArrowBtn from '../../elements/arrowbtn'
import './stuff-section.css'
import { team } from '../../utils/team'
export default function StuffSection() {
        const handleSwitchText = () => {
        setCurrentIndex((currentIndex + 1) % team.length);
        setNextIndex((currentIndex + 2) % team.length);
        };

        const goBackToPreviousText = () => {
        if (currentIndex > 0) {
            setCurrentIndex((currentIndex - 1) % team.length);
            setNextIndex(currentIndex % team.length);
            console.log(currentIndex, nextIndex);
        } else if (currentIndex === 0) {
            setCurrentIndex(team.length - 1);
            setNextIndex(0);
        }
        };

    const [currentIndex, setCurrentIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState(1);

    return (

        <Container className='stuffSection'>
            <Row>
                <Col md={6}>
                    <Container> <img alt='' src={team[currentIndex].image} className='bigImg' /> </Container>
                </Col>
                <Col md={6}>
                    <Row className="text-teko">
                        <Col className='section-title'> <img alt='' src={locationIcon} className='locationIcon' /> INCONTRA IL NOSTRO PERSONALE</Col></Row>
                    <Row className="text-teko title">{team[currentIndex].name}</Row>
                    <Row className="text-teko title">{team[currentIndex].title}</Row>
                    <Row className='text-description'>
                        {team[currentIndex].description.split('\n').map((paragraph, index) => (
                            <React.Fragment key={index}>
                                {paragraph}
                                <br />
                            </React.Fragment>))
                            }
                            <ul className="destination-list">
                {team[currentIndex].travelDestinations && team[currentIndex].travelDestinations.map((destination, index) => (
                    <li key={index}>{destination}</li>
                ))}
            </ul>
            {team[currentIndex].travelDestinations && "Per Lui il mondo è un libro e chi non viaggia ne legge solo una pagina."}
                         </Row>
                    <Row className='next-team-section'>
                        <Col md={2} >
                            <img alt='' src={team[nextIndex].image} />
                        </Col>
                        <Col className="text-teko " md={6}>
                            <Row className="next-team-member-name">{team[nextIndex].name}</Row>
                            <Row>{team[nextIndex].title}</Row>
                        </Col>
                        <Col md={4}>
                            <ArrowBtn handleSwitchText={handleSwitchText} goBackToPreviousText= {goBackToPreviousText}></ArrowBtn>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}