import React from 'react';
import './input-element.css'
const InputElement = ({ type, hintText, name }) => {

    if ((type === "text") || (type === "number") || (type === "email")) {
        return <input type={type} placeholder={hintText} required  name = {name} />
    } else if (type === "select") {
        return (<select id="country-select"   name = {name}>
            <option value="">Inserisci il tuo messaggio</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Germany">Germany</option>
            <option value="Italy">Italy</option>
        </select>);
    } else if (type === "text-area") {
        return (<textarea  name = {name} placeholder={hintText} maxLength='1000' minLength='50' >
        </textarea>)
    }

}
export default InputElement;