import React from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase_setup/firebase';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const navigate = useNavigate();
    const handleSignOut = () => {
        signOut(auth)
            .then(() => {
                navigate('/super-admin')
            })
            .catch((error) => {
                // An error occurred while signing out
                console.log('Error signing out:', error);
            });
    };
    const card = function (link: string, title: string) {
        return (<div className="col-md-4">
            <div className="card mb-4">
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <Link to={link} className="btn btn-primary" style={{ color: 'green' }}>Go</Link>
                </div>
            </div>
        </div>);
    }

    return (
        <div className="container mt-5">
            <h1 className="mb-4">Dashboard</h1>
            <button onClick={handleSignOut} >Sign Out</button>
            <Link to="/"> Home</Link>
            <div className="row">
                {card("/admin/add-tour", 'Add Tour')}
                {card("/admin/add-testimonials", 'Add Testimonials')}
                {card("/admin/add-book", 'Add Book')}
                {card("/admin/gallery", 'Gallery')}
                {card("/", 'Team')}
            </div>
        </div>
    );
};

export default Dashboard;
