import React from 'react';
import { useCountdown } from '../../hooks/use-countdown';
import DateTimeDisplay from './date-time-display';
import './countdown-timer.css';

const ExpiredNotice = () => {
  return (
        <div className="show-counter">
      <a
        href="/"
        target="_blank"
        rel="noopener noreferrer"
        className="countdown-link text-teko"
      >
        <DateTimeDisplay value="0"type={'Days'}  />
        <DateTimeDisplay value="0" type={'Hours'} isDanger={false} />
        <DateTimeDisplay value="0" type={'Mins'} isDanger={false} />
        <DateTimeDisplay value="0" type={'Seconds'} isDanger={false} />
      </a>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <a
        href="/"
        target="_blank"
        rel="noopener noreferrer"
        className="countdown-link text-teko"
      >
        <DateTimeDisplay value={days} type={'Giorni'} isDanger={days <= 1} />
        <DateTimeDisplay value={hours} type={'Ore'} isDanger={false} />
        <DateTimeDisplay value={minutes} type={'Minuti'} isDanger={false} />
        <DateTimeDisplay value={seconds} type={'Secondi'} isDanger={false} />
      </a>
    </div>
  );
};

const CountdownTimer = (nextEventDate) => {
  const [days, hours, minutes, seconds] = useCountdown(nextEventDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
