import React, { useState, useCallback, useEffect } from 'react';
import NavBar from "../navbar/nav-bar";
import HeaderPage from "../secondary-hero-image/secondary_header_page";
import bgImage from '../../assets/book-cover.png'
import "./book-details.css";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../../elements/button/button_element";
import { FaBookOpen } from "react-icons/fa";
import SectionTitle from "../../elements/section-title/section-title";
import BookCard from "../book-card/book-card";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ImageViewer from 'react-simple-image-viewer';
import { useParams } from "react-router-dom";

import { fireStore } from '../../firebase_setup/firebase';

const BookDetails = () => {
    const [books, setBooks] = useState([]);
    const [selectedBook, setSelectedBook] = useState();
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();

    const fetchData = useCallback(() => {
        fireStore.collection('books').onSnapshot(snapshot => {
            const booksData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setBooks(booksData);
            if (booksData.length > 0) {
                setSelectedBook(booksData.find((book) => book.id === id));
            }
            setIsLoading(false);
        });
    }, [id]);

    useEffect(() => {
        fetchData();
    }, []);


    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
if (isLoading) {
    return ;
}
    return (  <>
            <HeaderPage image={bgImage} title={selectedBook.title} path={`HOME  > Libri > ` + selectedBook.title} />
            <NavBar withBg={true} />
            <Container className="top">
                <Row>
                    <Col md={3}>
                        <img alt='' src={selectedBook.images[0]} onClick={() => openImageViewer(0)} style={{ cursor: 'pointer', height: '470px', width: '312px' }} />
                    </Col>
                    <Col md={9} className="book-detailes">
                        <h1 className=" text-teko">{selectedBook.title}</h1>
                        <p className="card-text takoFont"> <span style={{ color: '#8E8E8E' }}>by </span>{selectedBook.by}</p>
                        <p className="nanoSantaFont book-description" >{selectedBook.description}</p>

                        <Button text="leggere NOW" icon={<FaBookOpen className="icon-button" />} onClick={() => window.open(selectedBook.link, '_blank', 'noopener,noreferrer')} />

                        {/* <div className="book-social-media">
                        <h2 className="card-text text-teko share">SHARE :</h2>
                        <a href="/">
                            <FaInstagram className="book-social-media-icon" />
                        </a>
                        <a href="/">
                            <FaYoutube className="book-social-media-icon" />
                        </a>
                        <a href="/">
                            <FaFacebookMessenger className="book-social-media-icon" />
                        </a>
                        <a href="/">
                            <FaFacebookF className="book-social-media-icon" />
                        </a>
                    </div> */}
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <div style={{ margin: '30px 0' }}>
                    <SectionTitle text='Read our books' />
                    <p className='takoFont bigTitle'>Related Books</p>
                    <OwlCarousel
                        items={4.5}
                        className="owl-theme"
                        nav
                        margin={5}
                        responsive={{
                            0: {
                                items: 1,
                            },
                            400: {
                                items: 1,
                            },
                            600: {
                                items: 2,
                            },
                            700: {
                                items: 2,
                            },
                            1000: {
                                items: 3.5,
                            }
                        }}
                    >
                        {books.map(function (book, i) {
                            return <BookCard image={book.images[0]} key={i} title={book.title} by={book.by} id={book.id} />;
                        })}
                    </OwlCarousel>
                </div>
            </Container>
            {isViewerOpen && (
                <Container fluid style={{ margin: '5px' }}> <ImageViewer
                    src={selectedBook.images}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.6)"
                    }}
                /></Container>
            )}
        </>)
        
}
export default BookDetails;